<template>
  <div id="app">
    <header class="header">
      <div class="inner">
        <img src="@/assets/logo.jpg" alt="Loomis" class="logo" />
        <transition name="fade" v-if="$route.meta.isPublic" mode="out-in">
          <div class="heading" v-if="!$route.meta.isHQ">myLoomis <strong>Customer Portal</strong></div>
          <div class="heading" v-if="$route.meta.isHQ">myLoomis <strong>Head Office Control</strong></div>
        </transition>
        <transition-group v-if="!$route.meta.isPublic && $store.state.user_type != 'hq-as-customer'" name="fade"
          mode="out-in">
          <template>
            <div :key="1" class="menu step1" :class="{ show: showMenu }" role="navigation" aria-label="Main Menu">
              <ul>
                <li v-intro="`To return to the dashboard screen, click ‘Home’ in the Main Menu.`" v-intro-step="11">
                  <router-link :to="user_type == 'hq-user' ? { name: 'HQ Home' } : { name: 'Home' }"
                    class="step12">Home</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'My Details' }">My Details</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Messages' }">Messages
                    <span v-if="$store.getters.unread" class="unread-count">{{
          $store.getters.unread
        }}</span></router-link>
                </li>
                <li><a href @click.prevent="logOut">Log Out</a></li>
              </ul>
            </div>
            <a :class="{ show: showMenu }" :key="2" class="menuLink step1mobile" href aria-label="Open menu"
              @click.prevent="showMenu = !showMenu" v-if="!showMenu" v-intro="'This is the Main Menu. Use this to navigate around the main sections of the Portal at any time.'
          ">
              <font-awesome-icon icon="bars" aria-hidden="true" role="presentation" />
            </a>
            <a :class="{ show: showMenu }" :key="3" class="menuLink" href @click.prevent="showMenu = !showMenu"
              aria-label="Close menu" v-if="showMenu">
              <font-awesome-icon icon="times" aria-hidden="true" role="presentation" />
            </a>
          </template>
        </transition-group>

        <div class="hq-banner" v-if="$store.state.user_type == 'hq-as-customer'" :class="{ show: showMenu }" :key="1">
          <div class="hq-banner-grid">
            <div class="hq-banner-left">
              <span class="desktop">You are currently managing site</span>
              {{ formatAccNo($store.state.customer.account_number) }}<br /><strong>{{
          $store.state.customer.company
        }}</strong>
            </div>
            <div class="hq-banner-right">
              <router-link :to="{ name: 'HQ Home' }"><span>&lt;</span> return to Head Office dashboard</router-link>
            </div>
          </div>
        </div>
      </div>
    </header>
    <transition name="fade" mode="out-in">
      <div class="subheader" v-if="!$route.meta.isPublic">
        <div class="inner">
          <div class="step10 breadcrumb" role="navigation" aria-label="Breadcrumb">
            <strong>You are here:</strong>
            <span v-for="page in breadcrumb.slice(0, breadcrumb.length - 1)" :key="page.name">
              <router-link :to="{ name: page.name }">{{ page.label || page.name }}</router-link>
              &gt;&nbsp;</span>
            <strong aria-current="page" v-if="$route.name !== 'User Management'">{{ breadcrumb[breadcrumb.length -
          1].label || breadcrumb[breadcrumb.length -
            1].name }}</strong>

            <template v-if="$route.name == 'User Management'">
              <span v-if="view_state && view_state !== 'List'">
                <router-link to="" @click.native="view_state = 'List'">{{ breadcrumb[breadcrumb.length -
          1].name }}</router-link>
                &gt;&nbsp;</span>
              <span v-else><strong aria-current="page">{{ breadcrumb[breadcrumb.length -
          1].name }}</strong></span>

              <strong aria-current="page" v-if="view_state == 'UserEdit'">Edit User</strong>
              <strong aria-current="page" v-if="view_state == 'UserAdd'">Add User</strong>
            </template>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <div class="subheader contrast" v-if="showNewCutOffBanner &&
          ($route.name == 'Cash Order Management' ||
            $route.name == 'Order Cash Delivery' ||
            $route.name == 'Edit Cash Delivery' ||
            $route.name == 'Scheduled Orders' ||
            $route.name == 'Order History')
          ">
        <div class="inner">
          <strong>Update:</strong> Please ensure you place your orders before 11:30 am for next day delivery as our cut
          off has now changed.
        </div>
      </div>
    </transition>
    <div class="main">
      <transition name="slide-fade" mode="out-in">
        <router-view :key="$route.fullPath" />
      </transition>
    </div>
    <footer class="footer" v-if="!$route.meta.isPublic">
      <div class="inner">
        <strong>Something missing...?</strong> Suggestions welcome -
        <a href="mailto:uk.customerservices@loomis.com?subject=myLoomis Suggestion">click here to report an issue or
          provide feedback</a>
      </div>
    </footer>
    <div class="legal" role="navigation" aria-label="Legal Menu" :class="'alt'" v-if="!$route.meta.isPublic">
      <div class="inner">
        <a href="https://uk.loomis.com/legal-and-cookies/" target="_blank" rel="noreferrer">Privacy Policy</a>
        <a href="https://www.loomis.co.uk/cookies" target="_blank" rel="noreferrer">Cookie Policy</a>
        <a href="/accessibility" rel="noreferrer">Accessibility Statement</a>
        <div class="version">v5.0.1</div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatAccountNumber } from "@/util/vars"

export default {
  computed: {
    view_state: {
      get() {
        return this.$store.state.user_management_view_state
      },
      set(value) {
        this.$store.commit('setUserManagementViewState', value)
      }
    },
    showNewCutOffBanner() {
      return new Date() < new Date(2022, 6, 15)
    },
    user_type() {
      return this.$store.state.user_type
    },
    showMenu: {
      get() {
        return this.$store.state.showMenu
      },
      set(value) {
        this.$store.commit("setShowMenu", value)
      }
    },
    breadcrumb() {
      var arr = [{ name: this.$route.name }]
      if (this.$route.name == "HQ Home") arr[0].label = "Home"

      if (this.$route.meta.parent) arr.unshift({ name: this.$route.meta.parent.name })
      if (!this.$route.meta.isHome) {
        if (this.user_type == "customer" || this.user_type == "hq-as-customer") arr.unshift({ name: "Home" })
        if (this.user_type == "hq-user") arr.unshift({ name: "HQ Home", label: "Home" })
      }

      return arr
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch("logOut")
    },
    formatAccNo(acc_no) {
      return formatAccountNumber(acc_no)
    }
  },
  watch: {
    isIdle: function (val) {
      if (val) this.$store.dispatch("logOutInactive")
    }
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none !important;
}

.slicknav_menu {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  border: 1px solid #29383f;
}

a:focus-visible,
button:focus-visible {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}

img {
  max-width: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

body {
  box-sizing: border-box;
  line-height: 1.2;
}

html {
  font-size: 62.5%;
  min-height: 101vh;
}

@media screen and (max-width: 1300px) {
  html {
    font-size: 50%;
  }
}

body,
input,
select,
textarea {
  font-family: "Lato", sans-serif;
  font-size: 1.8rem;
  color: #5f6062;
}

input,
select,
textarea {
  border-radius: 3px;
}

.header {
  background: #29383f;
  color: white;
  padding: 2rem 0;
  height: 13.3rem;
  transition: all 0.5s;
  position: relative;
  width: 100%;
  font-weight: lighter;
  z-index: 100;
}

.v--modal-block-scroll .header {
  z-index: 1;
}

.authtype-modal.v--modal-overlay .v--modal-box {
  max-width: 1300px !important;
}

.inner {
  margin: auto;
  max-width: 92%;
  width: 1500px;
  position: relative;
}

.content-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 10rem;
}

.heading,
.menu,
.menuLink {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: right;
  font-size: 3rem;
  max-width: 70%;
  transition: all 0.3s;
}

.menu ul {
  font-size: 2rem;
  list-style-type: none;
  font-weight: bold;
}

.menu li {
  margin: 0.5rem 3rem 0.5rem 3rem;
  display: inline-block;
}

.menu a {
  display: block;
}

.menu a::after {
  display: block;
  content: " ";
  width: 0%;
  height: 0.5rem;
  background: #dc002e;
  transition: all 0.5s;
  position: relative;
  top: 1rem;
  opacity: 0;
}

.unread-count {
  background: white;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  line-height: 1.7rem;
  position: relative;
  top: -0.2rem;
  border-radius: 100%;
  color: #29383f;
  text-align: center;
  font-size: 1.6rem;
  margin-left: 0.5rem;
}

.menu a.router-link-exact-active::after {
  width: 100%;
  opacity: 1;
}

.unread-count {
  background: white;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  line-height: 1.7rem;
  position: relative;
  top: -0.2rem;
  border-radius: 100%;
  color: #29383f;
  text-align: center;
  font-size: 1.6rem;
  margin-left: 0.5rem;
}

.heading strong {
  display: block;
}

.logo {
  width: 15rem;
}

.main {
  position: relative;
  padding-bottom: 5rem;
  min-height: calc(100vh - 23rem);
}

h1 {
  font-size: 4rem;
  color: #dc002e;
  margin-bottom: 2rem;
}

h2 {
  font-size: 3rem;
  padding: 1.5rem 0;
  border-bottom: 1px solid #eee;
  margin-bottom: 2rem;
}

h2 img.dark {
  filter: brightness(0.4);
}

.home .left h2 {
  font-weight: normal;
}

.section-icon {
  height: 8rem;
  margin-top: -0.5rem;
  float: right;
}

.txt,
.form-input {
  border: 1px solid #ccc;
  padding: 1rem;
  font-size: 2rem;
  background: white !important;
  border-radius: 0.5rem;
  transition: all 0.3s;
}

.txt:focus {
  border: 1px solid #29383f;
}

.contrast {
  color: #dc002e;
}

.subheader {
  background: #dc002e;
  color: white;
  font-size: 1.5rem;
  padding: 1.5rem 0;
  width: 100%;
  z-index: 2;
}

.subheader.contrast {
  background: #CB4E0B;
}

.subheader strong:first-child {
  margin-right: 1rem;
}

.btn {
  border: 0;
  padding: 1rem;
  background: #5f6062;
  color: white;
  font-size: 2rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  cursor: pointer;
  background: linear-gradient(to bottom, #dc002e 50%, #ca0028 50%);
  background-size: 100% 200%;
  transition: all 0.5s;
}

.btn-filter {
  position: relative;
  top: -1px;
  margin-left: 1rem;
}

.btn:hover {
  background-position: right bottom;
}

.input-full {
  width: 100%;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.row {
  position: relative;
  margin-top: 1rem;
}

.loading {
  font-size: 6rem;
  margin-top: 10rem;
  color: #5f6062;
}

.legal {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 1.2rem;
  width: 100%;
}

.legal.alt {
  right: 0;
  left: auto;
  z-index: 2;
  position: static;
  background: #29383f;
  padding-top: 1rem;
}

.legal a,
.legal .version {
  display: inline-block;
  margin-right: 1rem;
  color: #666;
  background: #f5f5f5;
  padding: 0.5rem 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.legal.alt a,
.legal.alt .version {
  background: #424f55;
  color: white;
}

.version {
  background: none !important;
}

.legal a:hover {
  color: #b90025;
}

.legal.alt a:hover {
  color: white;
  background: #4a565c;
}

.error,
.success {
  border: 1px solid #dc002e;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  color: #dc002e;
  text-align: center;
  max-width: 100%;
  width: 80rem;
}

.success {
  border: 1px solid green;
  color: green;
}

.error.margin {
  margin-top: 2rem;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity transform;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
  transform: scale(1.5);
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.2s;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(-2rem);
  opacity: 0;
}

.content {
  padding: 5rem 0;
  position: relative;
  z-index: 1;
}

.menuLink {
  display: none;
  font-size: 5rem;
}

.inputs {
  margin: 3rem 0;
  max-width: 100%;
  width: 80rem;
}

.inputs.limited {
  width: 50rem;
}

.chk {
  position: relative;
  width: 2rem;
  cursor: pointer;
}

.input {
  background: #f3f3f3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 2rem 0 2rem;
}

.tour-modal .v--modal-box {
  border-top: 1rem solid #dc002e;
  border-radius: 0 0 1.2rem 1.2rem;
}

.modal-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0 0 0;
  max-width: 50rem;
}

.modal-heading {
  font-size: 3rem;
}

.modal-heading.contrast {
  margin-bottom: 3rem;
}

.modal-tour-image {
  display: block;
  margin: 4rem auto;
  margin-bottom: 5rem;
  width: 40rem;
}

.input label {
  min-height: 5rem;
}

.input:last-child {
  padding-bottom: 2rem;
}

.input label,
.modal-input label {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.input label.align-top {
  align-items: flex-start;
}

.actions button:hover {
  background: #b90025;
  color: white !important;
}

.tour-modal .actions button,
.tour-modal-finish .actions button {
  font-size: 2rem;
}

.tour-modal.v--modal-overlay {
  background: rgba(0, 0, 0, 0.5) !important;
}

.td-btn {
  text-align: right;
}

.nobr {
  white-space: nowrap;
}

.actions {
  margin-top: 3rem;
}

.actions-centre {
  display: flex;
  justify-content: center;
}

.actions button {
  border: 0;
  background: #ca0028;
  color: white;
  padding: 1rem 2rem;
  border-radius: 2.5rem;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: all 0.3s;
  font-size: 2.5rem;
  width: calc(50% - 0.5rem);
  max-width: 40rem;
}

.actions button img {
  height: 2.5rem;
  margin-bottom: -0.25rem;
  margin-left: 0.5rem;
}

.actions button.negative {
  background: #5f6062;
  color: white;
}

.actions button.negative:hover {
  background: #555557;
}

.v--modal-overlay {
  background: rgba(0, 0, 0, 0.7) !important;
}

.v--modal-overlay .v--modal-box {
  max-width: 850px !important;
  margin: auto;
  left: auto !important;
  padding: 3rem;
}

.v--modal-overlay.blinds-modal .v--modal-box {
  top: 5rem !important;
}

.modal-close {
  float: right;
  color: #ca0028;
  font-size: 2.5rem;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip {}

.tooltip .tooltip-inner {
  background: #f3f3f3;
  box-shadow: 1px 0px 15px -6px #666;
  border-radius: 16px;
  border-radius: 0.5rem;
  max-width: 50rem;
  font-size: 1.5rem;
  padding: 1rem 1.5rem;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  box-shadow: 1px 0px 15px -6px #666;
  border-color: #f3f3f3;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #ffffff;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.footer {
  background: #29383f;
  color: white;
  padding: 2rem 0;
}

.footer a,
.breadcrumb a {
  text-decoration: underline;
}

.breadcrumb a:focus-visible {
  outline: 2px solid #000 !important;
  outline-offset: 2px;
}

.info-circle img {
  height: 3.5rem;
}

.login .info-circle {
  float: right;
  top: 0.3rem;
}

.info {
  background: #CB4E0B;
  color: white;
  padding: 1rem;
  border-radius: 1rem;
  margin: 2rem 0;
  display: flex;
  align-items: center;
}

.info.larger {
  font-size: 2rem;
}

.info a {
  text-decoration: underline;
}

.confirmed {
  background: #2c8732;
}

.issue {
  background: #ca0028;
}

.info .info-circle {
  margin-right: 0.8rem;
  display: inline;
  display: flex;
  align-items: center;
}

.info .info-circle img {
  height: 2.5rem;
}

.info .info-content,
.info .info-left {
  display: flex;
  align-items: center;
  align-self: center;
}

.info-content {
  margin-top: auto;
  margin-bottom: auto;
}

.detail-static .detail-content {
  color: #8c8c8c;
}

.detail {
  display: grid;
  grid-template-columns: 1.5fr 2.7fr 0.8fr;
  background: #f3f3f3;
  padding: 1rem 2rem;
  font-size: 2.5rem;
  min-height: 7.2rem;
  margin-bottom: 1rem;
}

.detail_error {
  border: 1px solid #ca0028;
}

.detail_error_content {
  color: #ca0028;
  grid-column: 2 / 3;
  font-size: 1.5rem;
  padding-bottom: 1rem;
}

.detail.editable {
  grid-template-columns: 1fr 2fr;
}

.detail>* {
  display: flex;
  align-items: center;
  position: relative;
}

.detail-label {
  font-weight: bold;
}

.detail-content,
.back a {
  color: #ca0028;
}

.detail-input input {
  width: 90%;
  border: 1px solid #cdcdcd;
  padding: 1rem;
  font-size: 2.5rem;
  border-radius: 0.5rem;
}

.editable .detail-input input {
  width: 100%;
  padding-right: 5rem;
}

.detail-edit {
  text-align: right;
  color: #6e6e6e;
  transition: all 0.3s;
  font-size: 2rem;
}

.detail .reveal,
.row .reveal {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  height: 4rem;
  width: 4rem;
}

.detail .reveal img,
.row .reveal img {
  position: absolute;
  right: 0;
  top: 0;
}

.row input[type="password"] {
  padding-right: 6rem;
}

.row .reveal img {
  top: -0.3rem;
}

.detail-edit span {
  display: block;
  width: 100%;
  text-align: right;
}

.detail-edit img {
  height: 2.5rem;
  position: relative;
  margin-bottom: -0.5rem;
  filter: grayscale(100);
  opacity: 0.5;
  transition: all 0.3s;
}

.editing .detail-edit,
.detail-edit:hover {
  color: #ca0028;
}

.editing .detail-edit img,
.detail-edit:hover img {
  filter: grayscale(0);
  opacity: 1;
}

.blocks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-bottom: 3rem;
  padding-top: 1rem;
  width: 100%;
}

.block-link {
  background: #f3f3f3;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 3rem;
  font-size: 3rem;
  font-weight: bold;
  transition: all 0.3s;
}

.block-link>span {
  display: flex;
  align-items: center;
}

.block-link>span>span {
  display: block;
  width: 100%;
  text-align: center;
}

.block-link img {
  filter: grayscale(100);
  opacity: 0.8;
  width: 100%;
  max-width: 11rem;
  transition: all 0.3s;
}

.block-link:hover {
  color: #dc002e;
}

.block-link:hover img {
  filter: none;
  opacity: 1;
}

span.flatpickr-weekday {
  color: #ca0028 !important;
  font-weight: bold !important;
}

.flatpickr-calendar {
  box-shadow: 0px 0px 13px -2px #d3d3d3;
}

.flatpickr-months,
.flatpickr-monthDropdown-months {
  background: #ca0028 !important;
  border: 0 !important;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #ca0028 !important;
}

.flatpickr-current-month,
.arrowUp,
.arrowDown,
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  color: white !important;
  fill: white !important;
}

.numInputWrapper span,
.numInputWrapper span:active,
.numInputWrapper:hover {
  background: transparent !important;
  border: 0 !important;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after,
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-top-color: #fff !important;
  border-bottom-color: #fff !important;
}

.flatpickr-day {
  font-weight: bold !important;
}

.list {
  width: 100%;
  font-size: 2.2rem;
  border-collapse: collapse;
  border: 0;
}

.list th,
.list td {
  margin: 0;
  padding: 1rem 2rem;
}

.list thead th {
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  background: #f3f3f3;
  border-bottom: 2px solid #5f6062;
}

.list>tbody>tr:nth-child(4n),
.list>tbody>tr:nth-child(4n-1) {
  background: #f3f3f3;
}

.list>tbody>tr.hover {
  cursor: pointer;
}

.list .expand {
  height: 2rem;
  position: relative;
  top: 0.2rem;
}

.list>tbody>tr.hover:hover>td {
  color: #ca0028;
}

.list-more {
  text-align: center;
  color: #ca0028;
  padding: 2rem;
  padding-bottom: 0rem;
}

.list-more img {
  height: 2rem;
  position: relative;
  top: 0.2rem;
}

.list .expand {
  transition: all 0.3s;
}

.list .expanded .expand {
  transform: rotate(90deg);
}

.list-detail td {
  vertical-align: top;
  font-size: 1.8rem;
}

.list-den {
  border: 0;
  border-collapse: collapse;
  font-size: 1.6rem;
}

.list-den td {
  border-bottom: 1px solid #5f6062;
  padding: 0.75rem 1rem;
}

.list-den td:first-child {
  background: #f3f3f3;
  font-weight: bold;
}

.list>tbody>tr:nth-child(4n) .list-den td:first-child,
.list>tbody>tr:nth-child(4n-1) .list-den td:first-child {
  background: #fff;
}

.list-den tr:last-child td {
  border-bottom: 0;
}

.list-detail p.paddedbottom {
  margin-bottom: 1rem;
}

.list-detail p.paddedtop {
  margin-top: 5rem;
}

.list-detail .list-edit {
  color: #6e6e6e;
  margin-left: 2rem;
  transition: all 0.3s;
}

.list-detail .list-edit img {
  height: 2.5rem;
  filter: grayscale(100);
  opacity: 0.6;
  position: relative;
  top: 0.4rem;
  transition: all 0.3s;
}

.list-detail .list-edit:hover {
  color: #ca0028;
}

.list-detail .list-edit:hover img {
  filter: grayscale(0);
  opacity: 1;
}

.filter {
  padding-bottom: 2rem;
}

.filter-label {
  padding-right: 2rem;
  font-weight: bold;
}

.filter-input {
  background-image: url("/images/icons/field-icon-filter.png") !important;
  background-position: 96% center !important;
  background-size: 3rem !important;
  background-repeat: no-repeat !important;
  padding-right: 5rem;
}

.txt.full {
  width: 100%;
  max-width: 95rem;
}

.txt.date {
  background-image: url("/images/icons/field-icon-calendar.png") !important;
  background-position: 99% center !important;
  background-size: 3rem !important;
  background-repeat: no-repeat !important;
  padding: 1rem !important;
}

.reports-search-input,
.reports-type-select,
.reports-search-date {
  background-color: white;
  width: 100%;
  max-width: 40rem;
  margin-bottom: 3rem;
  background-image: url("/images/icons/field-icon-filter.png") !important;
  background-position: 96% center !important;
  background-size: 3rem !important;
  background-repeat: no-repeat !important;
  padding-right: 5rem;
  border: 1px solid #ccc;
  padding: 1rem;
  font-size: 2rem;
  border-radius: 0.5rem;
  transition: all 0.3s;
}

.reports-search-date {
  padding-bottom: 1rem !important;
  display: inline-block;
  width: 19.5rem;
  margin-right: 1rem;
  background-image: url("/images/icons/field-icon-calendar.png") !important;
}

.reports-type-select {
  background-image: none !important;
  cursor: pointer;
}

.reports-search-input::placeholder {
  color: rgb(204, 204, 204);
}

.btn-delete {
  font-size: 1.5rem;
  position: relative;
  top: 1rem;
  margin-bottom: 1rem;
}

.introjs-tooltip-header {
  display: none;
}

.introjs-tooltiptext {
  padding: 20px 20px 0 20px !important;
}

.introjs-bullets ul li a,
.introjs-bullets ul li a.active {
  width: 10px !important;
  height: 10px !important;
}

.introjs-nextbutton,
.introjs-nextbutton:active,
.introjs-nextbutton:hover,
.introjs-nextbutton:active {
  background: #b90025 !important;
  color: white !important;
  text-shadow: none !important;
  border: 1px solid #b90025 !important;
  outline: none !important;
  box-shadow: none !important;
}

.introjs-tooltipbuttons {
  border-top: 0 !important;
}

.introjs-tooltipReferenceLayer * {
  font-size: 1.8rem !important;
  font-family: "Lato" !important;
}

.introjs-helperLayer {
  box-shadow: rgb(00 00 00 / 50%) 0px 0px 0px 5000px !important;
  border-radius: 12px !important;
  padding: 7px !important;
  transform: translateX(-7px) translateY(-7px);
}

.introjs-disabled {
  display: none !important;
}

.form-input-holder.padded {
  padding: 1rem;
  font-weight: bold;
}

.hq-banner {
  transform: translateY(-50%);
  display: inline-block;
  width: calc(100% - 20rem);
  margin-left: 5rem;
  margin-bottom: -20rem;
}

.hq-banner a {
  background: #ca0028;
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  transition: all 0.3s;
  min-width: 35rem;
  font-size: 1.9rem;
  display: inline-block;
  font-weight: bold;
  transform: translateY(-25%);
}

.hq-banner a span {
  float: left;
}

.hq-banner a:hover {
  background: #b90025;
}

.hq-banner-left,
.hq-banner-right {
  padding: 1rem 0;
}

.hq-banner-right {
  text-align: right;
  padding: 2rem 0;
}

.hq-banner-grid {
  display: grid;
  grid-template-columns: 3fr 2fr;
}

@media screen and (min-width: 1001px) {
  .introjs-tooltip {
    min-width: 320px !important;
    max-width: 330px !important;
  }

  .introjs-showElement.step7 {
    max-width: 95%;
  }

  .introjs-showElement.step5 {
    max-width: 95%;
  }

  .tourpad {
    padding: 1rem;
    left: -1rem;
    top: -1rem;
    padding-bottom: 2rem;
    position: relative;
  }
}

@media screen and (max-width: 1000px) {
  h1 {
    font-size: 2.2rem;
  }

  h2 {
    font-size: 2rem;
  }

  .section-icon {
    height: 5rem;
  }

  .unread-count {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    top: -0.5rem;
  }

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #29383f;
    transform: none;
    max-width: 100%;
    text-align: left;
    opacity: 0;
    transition: all 0.5s;
    transform: rotateY(45deg);
    pointer-events: none;
  }

  .show.menu {
    opacity: 1;
    transform: rotateY(0);
    pointer-events: all;
  }

  .menu ul {
    width: 90%;
    margin-left: 5%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .menu ul li {
    display: block;
    font-size: 3rem;
    margin-left: 0;
    margin-bottom: 5vh;
  }

  .unread-count {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    top: -0.5rem;
  }

  .menuLink {
    display: block;
  }

  .audit {
    grid-template-columns: 1fr;
  }

  .desktop {
    display: none;
  }

  .search-filter {
    float: none;
    top: 0;
    margin-top: 2rem;
  }

  .content-grid {
    grid-template-columns: 1fr;
    gap: 5rem;
  }

  .info {
    display: grid;
    grid-template-columns: 1fr 10fr;
  }

  .info>div {
    display: flex !important;
    align-items: center;
    align-content: center;
  }

  .info-circle {
    display: block;
    width: 100%;
    text-align: center;
  }

  .detail {
    grid-template-columns: 1fr;
  }

  .detail_error_content {
    grid-column: 1 / 2;
  }

  .detail-label {
    padding: 0.5rem 0;
  }

  .detail-content {
    padding: 1.11rem 0;
  }

  .detail-input input {
    width: 100%;
  }

  .detail-edit {
    margin-top: -14.5rem;
  }

  .hq-banner-grid {
    grid-template-columns: 1fr;
  }

  .hq-banner-left {
    text-align: right;
  }

  .hq-banner-right {
    padding-bottom: 2rem;
  }

  .hq-banner-right a {
    font-size: 1.5rem;
  }

  .hq-banner-right a {
    min-width: auto;

    transform: translateY(-40%);
  }

  .hq-banner-right a span {
    float: none;
  }
}

@media screen and (min-width: 601px) {
  .inner.mobile {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .reports-search-input {
    max-width: 100%;
    margin-top: 1rem;
  }

  .footer strong::after {
    display: block;
    width: 100%;
    height: 10px;
    content: " ";
  }

  .inner.mobile {
    padding-bottom: 3rem;
  }

  .hide-cutoff-mobile .cutoff {
    display: none;
  }

  .mobile .mobile-hide {
    display: none;
  }

  .block-link {
    padding: 1.5rem;
    font-size: 2rem;
  }

  .block-link img {
    width: 100%;
  }

  .detail .reveal {
    top: 1.5rem;
  }

  .list-detail td {
    display: block;
    float: left;
    width: 50%;
  }

  .list {
    font-size: 1.5rem;
  }

  .blocks {
    grid-template-columns: 1fr;
  }

  .txt.date {
    width: 20rem;
    height: 5rem;
  }

  .tour-modal .actions button,
  .tour-modal-finish .actions button {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 400px) {
  .block-link {
    padding: 1.5rem 1rem;
    font-size: 1.7rem;
  }
}
</style>
