<template>
  <div style="padding-bottom: 2rem;">
    <template v-if="orders.length < 1 && !loading && !query && !error_message">
      <template v-if="$route.name !== 'Order History'">
        <error-panel content="There are no scheduled orders to view."></error-panel>
        <p>As new and future orders are added, they will appear here.</p>
      </template>
      <template v-if="$route.name == 'Order History'">
        <error-panel content="There are no historic orders to view."></error-panel>
        <p>As new orders are added, they will appear here.</p>
      </template>
    </template>
    <template v-if="error_message">
      <template>
        <error-panel content="There has been an error, please try again."></error-panel>
      </template>
    </template>
    <template v-if="loading">
      <div class="loading"><font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
      </div>
    </template>
    <template v-if="(orders.length || query) && !loading">
      <info-panel content="To view details about the order, click the row or arrow on the left to open."></info-panel>
      <div class="filter">
        <label class="filter-label">Filter by:</label>
        <input aria-label="Filter by date or reference" type="text" @input="filterChange" @keyup.enter="getOrders"
          v-model.trim="query" class="filter-input txt" placeholder="Date / Reference" />
        <button class="btn btn-filter" v-if="!!query" @click="getOrders">Go</button>
      </div>
      <p v-if="query && !orders.length">No matching orders!</p>
      <table v-if="orders.length" class="list">
        <caption class="visually-hidden">Scheduled Orders</caption>
        <thead>
          <tr>
            <th>Date</th>
            <th>Reference</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="order in filteredOrders">
            <tr class="hover selectable" tabindex="0" :class="view == order.id ? 'expanded' : ''" :key="order.id + 'top'"
              @click="view == order.id ? (view = null) : (view = order.id)"
              @keyup.enter="view == order.id ? (view = null) : (view = order.id)">
              <td class="nobr">
                <img src="/images/icons/right-col-link-arrow.png" class="expand" alt="link" />
                {{ formatDeliveryDate(order.delivery_date) }}
              </td>
              <td>{{ order.order_reference }}</td>
              <td>&pound;{{ order.value.toFixed(0) }}</td>
              <td>
                <font-awesome-icon icon="sync" v-if="$route.name !== 'Order History' && order.standing_order_id"
                  v-tooltip="'This ' + ($route.name !== 'Order History' ? 'is' : 'was') + ' part of a standing order'
                    " />
              </td>
            </tr>
            <tr :key="order.id + 'bottom'" v-show="view == order.id">
              <td colspan="4">
                <table class="list-detail">
                  <tr>
                    <td colspan="3">
                      <p class="contrast"><strong>Denominations:</strong></p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table class="list-den">
                        <tr>
                          <td>&pound;50</td>
                          <td>&pound;{{ order.note_50.toFixed(0) }}</td>
                        </tr>
                        <tr>
                          <td>&pound;20</td>
                          <td>&pound;{{ order.note_20.toFixed(0) }}</td>
                        </tr>
                        <tr>
                          <td>&pound;10</td>
                          <td>&pound;{{ order.note_10.toFixed(0) }}</td>
                        </tr>
                        <tr>
                          <td>&pound;5</td>
                          <td>&pound;{{ order.note_5.toFixed(0) }}</td>
                        </tr>
                        <tr>
                          <td>&pound;2</td>
                          <td>&pound;{{ order.coin_200.toFixed(0) }}</td>
                        </tr>
                        <tr>
                          <td>&pound;1</td>
                          <td>&pound;{{ order.coin_100.toFixed(0) }}</td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <table class="list-den">
                        <tr>
                          <td>50p</td>
                          <td>&pound;{{ order.coin_50.toFixed(0) }}</td>
                        </tr>
                        <tr>
                          <td>20p</td>
                          <td>&pound;{{ order.coin_20.toFixed(0) }}</td>
                        </tr>
                        <tr>
                          <td>10p</td>
                          <td>&pound;{{ order.coin_10.toFixed(0) }}</td>
                        </tr>
                        <tr>
                          <td>5p</td>
                          <td>&pound;{{ order.coin_5.toFixed(0) }}</td>
                        </tr>
                        <tr>
                          <td>2p</td>
                          <td>&pound;{{ order.coin_2.toFixed(0) }}</td>
                        </tr>
                        <tr>
                          <td>1p</td>
                          <td>&pound;{{ order.coin_1.toFixed(0) }}</td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <template v-if="order.timestamp < order.delivery_date">
                        <p class="contrast paddedbottom"><strong>Date order placed:</strong></p>
                        <p>
                          <strong>{{ formatTimestamp(order.timestamp) }}</strong>
                        </p>
                      </template>
                      <template v-if="$route.name !== 'Order History' && editable(order.delivery_date)">
                        <p class="contrast paddedtop">
                          <strong>Amend order:</strong>
                          <a @click.prevent="editOrder(order)" class="list-edit" href>Edit <img
                              src="/images/icons/edit-icon-o.png" alt="edit" /></a>
                        </p>
                        <button class="btn btn-delete" @click="showDeleteModal(order)">Delete Order</button>
                      </template>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="list-more" v-if="!all_returned">
        <a href @click.prevent="viewMore"><img src="/images/icons/right-col-link-arrow.png" alt="more" /> View
          more...</a>
      </div>
    </template>

    <modal :scrollable="true" height="auto" name="delete" width="90%">
      <a @click.prevent="$modal.hide('delete')" class="modal-close" href aria-label="Close">
        <font-awesome-icon icon="times" aria-hidden="true" role="presentation" />
      </a>
      <h2>Please confirm</h2>
      <p>Are you sure you want to delete this order?</p>
      <span class="error" v-if="delete_error_message">{{ delete_error_message }}</span>
      <div class="actions modal-actions">
        <button @click="_delete"><font-awesome-icon icon="check" aria-hidden="true" role="presentation" /> Yes</button>
        <button class="negative" @click="$modal.hide('delete')"><font-awesome-icon icon="times" aria-hidden="true"
            role="presentation" />
          No</button>
      </div>
    </modal>

    <modal :scrollable="true" height="auto" name="deletestandingorder" width="90%">
      <a @click.prevent="$modal.hide('deletestandingorder')" class="modal-close" href aria-label="Close">
        <font-awesome-icon icon="times" aria-hidden="true" role="presentation" />
      </a>
      <h2>Please confirm</h2>
      <p>Do you want to delete just this occurrence, or all orders in the series?</p>
      <div class="actions modal-actions">
        <button @click="_delete">Just this one</button>
        <button class="negative" @click="deleteAll">All in this series</button>
      </div>
    </modal>

    <modal :scrollable="true" height="auto" name="editstandingorder" width="90%">
      <a @click.prevent="$modal.hide('editstandingorder')" class="modal-close" href aria-label="Close">
        <font-awesome-icon icon="times" aria-hidden="true" role="presentation" />
      </a>
      <h2>Please confirm</h2>
      <p>Do you want to edit just this occurrence, or all orders in the series?</p>
      <div class="actions modal-actions">
        <button @click="$router.push('/edit-cash-delivery/' + order_to_edit.id)">Just this one</button>
        <button class="negative" @click="$router.push('/edit-standing-order/' + order_to_edit.id)">
          All in this series
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import api from "@/services/api"
import dateUtil from "date-and-time"

export default {
  data() {
    return {
      error_message: "",
      loading: true,
      getting_next_page: true,
      view: null,
      orders: [],
      page: 1,
      all_returned: false,
      query: "",
      order_to_delete: {},
      order_to_edit: {},
      delete_error_message: ""
    }
  },
  created() {
    this.getOrders()
  },
  computed: {
    filteredOrders() {
      return this.orders.filter(o => !o.deleted)
    }
  },
  methods: {
    editable(delivery_date) {
      return delivery_date > dateUtil.format(this.$store.getters.maxNonEditableDate, "YYYY-MM-DD")
    },
    showDeleteModal(item) {
      this.delete_error_message = ""
      this.order_to_delete = item
      if (this.order_to_delete.standing_order_id) this.$modal.show("deletestandingorder")
      else this.$modal.show("delete")
    },
    editOrder(item) {
      this.order_to_edit = item
      if (this.order_to_edit.standing_order_id) this.$modal.show("editstandingorder")
      else this.$router.push("/edit-cash-delivery/" + this.order_to_edit.id)
    },
    _delete() {
      this.delete_error_message = ""

      api
        .request("delete", "order?id=" + this.order_to_delete.id)
        .then(() => {
          this.$set(this.order_to_delete, "deleted", true)
          this.$modal.hide("delete")
          this.$modal.hide("deletestandingorder")
        })
        .catch(() => {
          this.delete_error_message = "There was an error, please try again"
        })
    },
    deleteAll() {
      this.delete_error_message = ""

      api
        .request("delete", "order?standing_order_id=" + this.order_to_delete.standing_order_id)
        .then(() => {
          this.orders
            .filter(
              o => o.standing_order_id == this.order_to_delete.standing_order_id && this.editable(o.delivery_date)
            )
            .forEach(o => this.$set(o, "deleted", true))

          this.$modal.hide("delete")
          this.$modal.hide("deletestandingorder")
        })
        .catch(() => {
          this.delete_error_message = "There was an error, please try again"
        })
    },
    filterChange() {
      if (!this.query) {
        this.loading = true
        this.page = 1
        this.orders = []
        this.getOrders()
      }
    },
    getOrders() {
      this.error_message = ""

      if (this.page > 1) this.getting_next_page = true

      var type = ""
      switch (this.$route.name) {
        case "Order History":
          type = "history"
          break
        default:
          type = "scheduled"
      }

      var url = "order?type=" + type
      if (this.query) url += "&filter=" + this.query
      else url += "&page=" + this.page + "&offset=" + (this.orders.length - this.filteredOrders.length)

      api
        .request("get", url)
        .then(res => {
          if (this.query) {
            this.orders = res.orders
            this.all_returned = true
          } else {
            this.orders = this.orders.concat(res.orders)
            if (res.last) this.all_returned = true
            else this.all_returned = false
          }
        })
        .catch(err => (this.error_message = "There has been an error, please try again."))
        .finally(() => {
          this.loading = false
          this.getting_next_page = false
        })
    },
    formatDeliveryDate(d) {
      return dateUtil.format(dateUtil.parse(d, "YYYY-MM-DD"), "DD/MM/YYYY")
    },
    formatTimestamp(d) {
      return dateUtil.format(new Date(d), "D MMMM YYYY")
    },
    viewMore() {
      this.page++
      this.getOrders()
    }
  }
}
</script>

<style>
.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.selectable:focus-visible {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}
</style>