<template>
  <div>
    <div class="container">
      <table class="hq-status">
        <thead>
          <tr>
            <td>Site</td>
            <td>Customer Ref</td>
            <td v-if="type == 'completed'">Time completed</td>
            <td v-if="type == 'cancelled'">Time cancelled</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="status in statuses" :key="status.account_number">
            <td><span>{{ status.account_number }}</span><br /><span>{{ status.company }}</span></td>
            <td>{{ status.customer_ref }}</td>
            <td v-if="type">{{ status.timestamp }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <slot name="footer">
    </slot>
  </div>
</template>

<script>
export default {
  props: ["statuses", "type"],
  data() {
    return {}
  }
}
</script>

<style scoped>
.container {
  max-height: 30rem;
  overflow-y: auto;
}

.hq-status {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

.hq-status td {
  padding: 1rem 2rem;
  border-top: 1px solid #eee;
}

.hq-status thead td {
  background: #eee;
  font-weight: bold;
}

.hq-status tr:nth-child(even) td {
  background: #f8f8f8;
}

.hq-status button {
  background: #ca0028;
  border: 0;
  padding: 0.5rem 2.5rem;
  border-radius: 2rem;
  color: white;
  cursor: pointer;
  font-size: 1.6rem;
}

.hq-status button:hover {
  background: #b80125;
}
</style>