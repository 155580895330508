import axios from "axios"
import cognitoUser from "./cognito-user"
import store from "@/store"

var isLocal = location.hostname.indexOf("localhost") > -1

const normalTimeout = 30000
const longTimeout = 30000
const extendedTimeout = 60000

function getRequestConfig(url, responseType = "json") {
  return new Promise((resolve, reject) => {
    cognitoUser
      .currentUserSession()
      .then(userSession => {
        const headers = {
          "Content-Type": "application/json",
          authorization: userSession.session.idToken.jwtToken,
          "cognito-pool": store.state.user_type
        }

        if (store.state.customer) {
          headers["cognito-sub"] = store.state.customer.user_name
          if (store.state.user_type === "hq-as-customer") {
            headers["account-number"] = store.state.customer.account_number
          }
        }

        if (store.state.hq_user) {
          headers["cognito-sub"] = store.state.hq_user.user_name
        }

        let timeout = normalTimeout
        if (url.startsWith("account") || url.startsWith("shortage") || url.startsWith("safe-point")) {
          timeout = longTimeout
        }
        if (url.startsWith("dashboard") || url.startsWith("package")) {
          timeout = extendedTimeout
        }

        resolve({
          baseURL: isLocal ? "http://localhost:3003/api/" : "/api/",
          headers,
          timeout,
          responseType
        })
      })
      .catch(error => reject(error))
  })
}

function unauthRequest() {
  return new Promise(resolve => {
    resolve(
      axios.create({
        baseURL: isLocal ? "http://localhost:3003/api/" : "/api/",
        headers: { "Content-Type": "application/json" },
        normalTimeout
      })
    )
  })
}

export default {
  request: function (method, url, data = {}, timeout = false, responseType = "json") {
    return new Promise((resolve, reject) => {
      getRequestConfig(url, responseType)
        .then(config => {
          const req = axios.create(config)
          return req[method](url, data)
        })
        .then(response => resolve(response.data))
        .catch(error => {
          if (!timeout && error.message && error.message.startsWith("timeout")) {
            this.request("post", "_", { method, url }, true, responseType)
          }
          reject(error)
        })
    })
  },
  unauthRequest: function (method, name, data = {}) {
    return new Promise((resolve, reject) => {
      unauthRequest().then(req =>
        req[method](name, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      )
    })
  }
}
