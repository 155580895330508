<template>
  <main>
    <section key="login" class="login" v-if="view_state == 'login'">
      <h1>Login</h1>
      <div class="login-inner">
        <div class="row">
          <input ref="login_email" aria-label="Email Address" class="txt input-full" type="email"
            placeholder="Email Address" v-model.trim="email" @keyup.enter="logIn" />
        </div>
        <div class="row">
          <input aria-label="Password" class="txt input-full" :type="reveal_login_password ? 'text' : 'password'"
            placeholder="Password" v-model.trim="password" @keyup.enter="logIn" />
          <a href class="reveal" @click.prevent="reveal_login_password = !reveal_login_password"><img
              v-if="reveal_login_password == false" src="/images/icons/field-icon-reveal.png" alt="Reveal password" />
            <img v-else class="reveal" src="/images/icons/field-icon-hide.png" alt="Hide password" /></a>
        </div>
        <div class="error" v-if="login_error_message">{{ login_error_message }}</div>
        <div class="row">
          <button class="btn input-full" :disabled="logging_in" @click="logIn">
            <template v-if="logging_in">
              <font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
            </template>

            <template v-else> Submit </template>
          </button>
        </div>
        <div class="row help">
          <a href @click.prevent="view_state = 'signup'" v-if="!$route.meta.isHQ">&gt; Sign-up for an Account</a>
          <a href @click.prevent="viewResetPassword" :class="!$route.meta.isHQ ? 'help-link-right' : ''">&gt;
            Reset
            Password</a>
          <div v-if="!$route.meta.isHQ">
            <a href="https://www.loomis.co.uk/our-solutions/myloomis-customer-portal/frequently-asked-questions"
              target="_blank" rel="noreferrer">&gt; Frequently Asked Questions</a>
          </div>
          <div> <a href="/accessibility" rel="noreferrer">&gt; Accessibility Statement</a></div>

        </div>
      </div>
    </section>
    <section key="reset" class="login" v-if="view_state == 'reset-password'">
      <h1>Reset password</h1>
      <div class="login-inner">
        <div class="row">
          <input v-model="reset_email" aria-label="Email Address" class="txt input-full" type="email" ref="reset_email"
            placeholder="Email Address" />
        </div>
        <div class="row">
          <input v-model="reset_account" aria-label="12 Digit Location Number" @keyup.enter="resetPassword"
            class="txt input-full" type="text"
            :placeholder="!$route.meta.isHQ ? '12 Digit Location Number' : '8 Digit Account Number'" v-tooltip="!$route.meta.isHQ
      ? 'Please enter your 12 digit location number. This can be found on the barcode the driver scans when attending your premises. NOTE: If your barcode shows a 10 digit location number, you will need to add \'02\' at the beginning.'
      : ''
      " />
        </div>

        <div class="success" v-if="reset_success_message">{{ reset_success_message }}</div>
        <div class="error" v-if="reset_error_message">{{ reset_error_message }}</div>

        <div class="row" v-if="!reset_success_message">
          <button class="btn input-full" @click="resetPassword">Submit</button>
        </div>
        <div class="row help">
          <a href @click.prevent="view_state = 'login'">&gt; Login</a>
        </div>
      </div>
    </section>
    <section key="signup" class="login signup" v-if="view_state == 'signup'">
      <h1>Sign-up for an Account</h1>
      <div class="login-inner">
        <div class="row full">
          <p><strong>Please fill out the form below with your details.</strong></p>
          <p>
            NOTE: Only use a business mobile and not a personal number.<br />If you do not have a business number, enter
            a landline number and be sure to select "Browser Authenticator" or "Email Authentication" on the following
            screen.
          </p>
        </div>
        <div class="row">
          <input aria-label="Full Name" class="txt input-full" type="text" placeholder="Full Name" ref="signup_name"
            v-model.trim="signup_name" />
        </div>
        <div class="row">
          <input aria-label="Company Name" class="txt input-full" type="text" placeholder="Company Name"
            v-model.trim="signup_company" />
        </div>
        <div class="row">
          <input aria-label="12 Digit Location Number" class="txt input-full" type="text"
            placeholder="12 Digit Location Number" v-tooltip="`Please
          enter your 12 digit location number. This can be found on the barcode the driver scans when attending your
          premises. NOTE: If your barcode shows a 10 digit location number, you will need to add '02' at the beginning.`
      " v-model.trim="signup_account_number" />
        </div>
        <div class="row">
          <input aria-label="Mobile" class="txt input-full" type="tel" placeholder="Mobile"
            v-model.trim="signup_telephone" />
        </div>
        <div class="row">
          <input aria-label="Email Address" class="txt input-full" type="email" placeholder="Email Address"
            v-model.trim="signup_email" />
        </div>
        <div class="row">
          <input aria-label="Password" class="txt input-full" :type="reveal_signup_password ? 'text' : 'password'"
            placeholder="Password"
            v-tooltip="`Password must be a minimum of 10 characters and include a letter, a number, and a special character`"
            v-model="signup_password" />
          <a href class="reveal" @click.prevent="reveal_signup_password = !reveal_signup_password"><img
              v-if="reveal_signup_password == false" src="/images/icons/field-icon-reveal.png" alt="Show password" />
            <img v-else class="reveal" src="/images/icons/field-icon-hide.png" alt="Hide password" /></a>
        </div>
        <div class="row">
          <input aria-label="Confirm Password" class="txt input-full"
            :type="reveal_signup_confirm ? 'text' : 'password'" placeholder="Confirm Password"
            v-model="signup_confirm" />
          <a href class="reveal" @click.prevent="reveal_signup_confirm = !reveal_signup_confirm"><img
              v-if="reveal_signup_confirm == false" src="/images/icons/field-icon-reveal.png" alt="Show password" />
            <img v-else class="reveal" src="/images/icons/field-icon-hide.png" alt="Hide password" /></a>
        </div>
        <div class="row full">
          <input class="chk" id="chkDetails" type="checkbox" v-model="signup_details" />
          <label for="chkDetails">I agree that my details will be checked against existing records held at
            Loomis.</label>
        </div>
        <div class="row full">
          <input class="chk" id="chkPrivacy" type="checkbox" v-model="signup_privacy" />
          <label for="chkPrivacy">I agree with the
            <a href="https://uk.loomis.com/legal-and-cookies/" target="_blank" rel="noreferrer" class="privacy">Loomis
              Privacy Policy</a>
            and understand that my details will be used to contact me in relation to this enquiry.</label>
        </div>
        <div class="error" v-if="signup_error_message">{{ signup_error_message }}</div>
        <div class="row">
          <button :disabled="disable_signup" class="btn input-full" @click="signUp(true)">Submit</button>
        </div>
        <div class="row help">
          <a href @click.prevent="view_state = 'login'">&gt; Login</a>
          <a href="https://www.loomis.co.uk/our-solutions/myloomis-customer-portal/frequently-asked-questions"
            target="_blank" class="help-link-right" rel="noreferrer">&gt; Frequently Asked Questions</a>
        </div>
      </div>
    </section>
    <section key="temporary" class="login" v-if="view_state == 'new-password'">
      <h1>Set a password</h1>
      <p>Please set a new password for your user account.</p>
      <div class="login-inner">
        <div class="row">
          <input aria-label="Password" ref="temporary_password" class="txt input-full" type="password"
            placeholder="Password" v-model="new_password" @keyup.enter="setNewPassword" />
        </div>
        <div class="row">
          <input aria-label="Confirm Password" class="txt input-full" type="password" placeholder="Confirm Password"
            v-model="confirm_password" @keyup.enter="setNewPassword" />
        </div>
        <div class="error" v-if="temporary_error_message">{{ temporary_error_message }}</div>
        <div class="row">
          <button class="btn input-full" @click="setNewPassword">Submit</button>
        </div>
      </div>
    </section>
    <section key="authsetup" class="login long" v-if="view_state == 'setup-software'">
      <h1>Setup Authentication</h1>
      <p>As per your selection, we'll now setup another layer of authentication via your mobile device.</p>
      <p>Don't worry, it will only take a few minutes to setup, and you'll only need to do this once!</p>
      <div class="login-inner">
        <div class="step" v-if="auth_slide == 1">
          <div class="row">
            <p class="step-heading">Step 1 of 3</p>
            <p><strong>Please install Google Authenticator on your mobile device.</strong></p>
            <p>
              This free app is available for Apple and Android devices. Just search "Google Authenticator" in your app
              store.
            </p>
            <p>
              Alternatively, you can download and use the free browser extension to verify access via your choice of
              browser. See link below on how to download and set this up.
            </p>
            <p class="minor">
              Note: if you already have an alternative multi-factor app, feel free to use that instead.
            </p>
          </div>
          <div class="row">
            <button class="btn input-full" @click="auth_slide = 2">I have installed the app</button>
          </div>
        </div>
        <div class="step" v-if="auth_slide == 2">
          <div class="row">
            <p class="step-heading">Step 2 of 3</p>
            <p>Tap the plus icon in your authenticator app.</p>
            <p>Then tap "Scan a QR Code" and scan the QR code displayed below:</p>
          </div>

          <div class="row qr"><img :src="qr_image" alt="QR code" /></div>
          <div class="row minor">
            If you can't scan the QR code, choose "Enter a setup key" then copy and paste this text:
          </div>
          <div class="row code">{{ qr_secret }}</div>
          <div class="row">
            <button class="btn-copy" @click="copyCode">
              <font-awesome-icon icon="copy" aria-hidden="true" role="presentation" /> <span
                id="btn-copy-txt">Copy</span>
            </button>
            <input type="hidden" id="qr_secret" :value="qr_secret" />
          </div>
          <div class="row">
            <button class="btn input-full" @click="goToSlide3">I have scanned the code</button>
          </div>
        </div>
        <div class="step" v-if="auth_slide == 3">
          <div class="row">
            <p class="step-heading">Step 3 of 3</p>
            <p>Type the code you see on your mobile device into the box below and click Submit:</p>
          </div>
          <div class="row">
            <input ref="authsetup_token" class="txt input-full central" type="tel" aria-label="Enter Code"
              placeholder="Enter Code" v-model.trim="authsetup_token" @keyup.enter="verifyToken" />
          </div>
          <div class="error" v-if="authsetup_error_message">{{ authsetup_error_message }}</div>
          <div class="row">
            <button class="btn input-full" @click="verifyToken">Submit</button>
          </div>
        </div>
        <div class="step" v-if="auth_slide == 4">
          <div class="row">
            <p class="step-heading">Setup Complete!</p>
            <p>
              Thank you for completing the signup process and successfully setting up authentication via your mobile
              device.
            </p>
            <p>
              When you log in, we'll ask for the 6 digit code generated by your authentication app, so be sure to have
              your mobile device handy.
            </p>
            <p>We'll be in touch as soon as your account has been activated by our administration team.</p>
          </div>
          <!--<div class="row">
            <button class="btn input-full" @click="view_state = 'login'">Finish</button>
          </div>-->
        </div>

        <div class="row help">
          <div class="help-heading">
            Need help..?
            <span class="info-circle"><img src="/images/icons/right-col-info-icon-grey.png" alt="info" /></span>
          </div>
          <div>
            <a href="/downloads/Loomis-Google-Authenticator-Instructions.pdf" target="_blank" rel="noreferrer"
              class="help-link-spaced">&gt; How to download and use Google Authenticator</a><br />
            <a href="/downloads/Loomis-Authenticator-Browser-Plugin-Instructions.pdf" target="_blank" rel="noreferrer"
              class="help-link-spaced">&gt; How to download and use the browser extension</a><br />
            <a href="https://www.loomis.co.uk/case-submission" target="_blank" rel="noreferrer">&gt; Create a case
              here</a>
          </div>
        </div>
      </div>
    </section>
    <section key="signupsuccess" class="login long" v-if="view_state == 'signup-success'">
      <h1><br />Sign-up complete!</h1>
      <p>Thank you for completing the sign-up process.</p>
      <p>We'll be in touch as soon as your account has been activated by our administration team.</p>
      <p v-if="signup_auth_type == 'SMS'">
        Be sure to have your mobile handy the next time you visit our portal, as we'll send your 6-digit authentication
        code by SMS.
      </p>
      <p v-if="signup_auth_type == 'Email'">
        Next time you visit our portal, we'll send your 6-digit authentication code by email.
      </p>
    </section>
    <section key="auth" class="login" v-if="view_state == 'authentication-code'">
      <h1>Authentication Code</h1>
      <p>
        {{
      auth_type == "SMS_MFA"
        ? "Please enter the Authentication Code we just sent you by SMS."
        : auth_type == "EMAIL_MFA"
          ? "Please enter the Authentication Code we just sent you by email."
          : "Please enter the Authentication Code as generated by your mobile app."
    }}
      </p>
      <div class="login-inner">
        <div class="row">
          <input ref="auth_code" aria-label="6 Digit Code" class="txt input-full central" type="tel"
            placeholder="6 Digit Code" v-model.trim="auth_code" @keyup.enter="MFA" />
        </div>
        <div class="error" v-if="auth_code_error_message">{{ auth_code_error_message }}</div>
        <div class="row">
          <button class="btn input-full" @click="MFA">

            <template v-if="logging_in_mfa">
              <font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
            </template>

            <template v-else> Submit </template>
          </button>
        </div>
      </div>
    </section>

    <modal :clickToClose="false" :scrollable="true" height="auto" name="authtype" width="90%" class="authtype-modal"
      @closed="disable_signup = false">
      <a @click.prevent="$modal.hide('authtype')" class="modal-close" href aria-label="Close">
        <font-awesome-icon icon="times" aria-hidden="true" role="presentation" />
      </a>
      <div class="modal-heading">Securing your Access to</div>
      <div class="modal-heading contrast">myLoomis Customer Portal</div>
      <p>
        <strong>Our Portal requires Two-Factor Authentication. You will need to be able to recieve these codes via one
          of
          your devices.</strong>
      </p>
      <p>Please choose your preferred method: -</p>
      <div class="grid" :class="signup_email_allowed ? 'triple' : ''">
        <div>
          <img src="/images/auth-app.jpg" alt="Authenticator app" />
          <div class="type-label">
            <input type="radio" v-model="signup_auth_type" name="radAuth" value="Software" id="radAuthSoftware" />
            <label for="radAuthSoftware">Authenticator App</label>
          </div>
          <p class="small central">
            Authenticate on your smartphone or tablet device using Google Authenticator.
            <a href="/downloads/Loomis-Google-Authenticator-Instructions.pdf" target="_blank" rel="noreferrer"
              aria-label="View instructions for how to authenticate on your smartphone or tablet">View
              Instructions</a>
            &gt;
          </p>
          <p class="small central">
            If you don't have a smartphone or tablet device you can use a browser plugin as an alternative.
            <a href="/downloads/Loomis-Authenticator-Browser-Plugin-Instructions.pdf" target="_blank" rel="noreferrer"
              aria-label="View instructions for if you don't have a smartphone or tablet device">View Instructions</a>
            &gt;
          </p>
        </div>
        <div>
          <img src="/images/auth-sms.jpg" alt="Authenticator sms" />
          <div class="type-label">
            <input type="radio" v-model="signup_auth_type" name="radAuth" value="SMS" id="radAuthSMS" />
            <label for="radAuthSMS">SMS</label>
          </div>
          <p class="small central">
            If you don't have a smartphone or tablet device, you can opt for SMS authentication.
          </p>
          <p class="small central">
            This means we will send you a verification code via text message to the business mobile number registered on
            the account.
          </p>
        </div>
        <div v-if="signup_email_allowed">
          <img src="/images/auth-email.jpg" alt="Authenticator email" />
          <div class="type-label">
            <input type="radio" v-model="signup_auth_type" name="radAuth" value="Email" id="radAuthEmail" />
            <label for="radAuthEmail">Email</label>
          </div>
          <p class="small central">
            This means we will send you a verification code via email to the address registered on the account.
          </p>
        </div>
      </div>
      <p class="small central">NOTE: If you do not have a business mobile number, please select ‘Authenticator App’</p>
      <div class="actions modal-actions">
        <button :disabled="disable_signup_confirm" @click="signUp(false)">
          Continue <font-awesome-icon icon="chevron-right" aria-hidden="true" role="presentation" />
        </button>
      </div>
    </modal>
  </main>
</template>

<script>
import QRCode from "qrcode"

import cognito from "@/services/cognito-user"
import api from "@/services/api"
import phoneNumber from "awesome-phonenumber"

export default {
  data() {
    return {
      email: "",
      password: "",
      login_error_message: "",
      logging_in: false,
      logging_in_mfa: false,

      reveal_login_password: false,
      reveal_signup_password: false,
      reveal_signup_confirm: false,

      signup_name: "",
      signup_company: "",
      signup_account_number: "",
      signup_telephone: "",
      signup_email: "",
      signup_password: "",
      signup_confirm: "",
      signup_auth_type: "SMS",
      signup_details: false,
      signup_privacy: false,
      signup_email_allowed: false,
      signup_email_domain: "",
      signup_error_message: "",
      disable_signup: false,
      disable_signup_confirm: false,

      reset_email: "",
      reset_account: "",
      reset_error_message: "",
      reset_success_message: "",

      qr_image: "",
      qr_secret: "",

      authsetup_token: "",
      authsetup_error_message: "",

      new_password: "",
      confirm_password: "",
      temporary_error_message: "",

      auth_code: "",
      auth_code_error_message: "",
      auth_slide: 1,

      view_state: "login",
      auth_type: "" // "SOFTWARE_TOKEN_MFA" or "SMS_MFA" or "EMAIL_MFA"
    }
  },
  mounted() {
    if (this.$route.meta.isHQ) this.$store.commit("setUserType", "hq-user")
    else this.$store.commit("setUserType", "customer")

    this.$refs.login_email.focus()
    this.$route.path == "/sign-up/" || this.$route.path == "/sign-up" ? (this.view_state = "signup") : ""
  },
  methods: {
    goToSlide3() {
      this.auth_slide = 3
      this.$refs.authsetup_token.focus()
    },
    viewResetPassword() {
      this.reset_success_message = ""
      this.reset_error_message = ""
      this.reset_email = ""
      this.reset_account = ""

      this.view_state = "reset-password"
    },
    resetPassword() {
      this.reset_success_message = ""
      this.reset_error_message = ""

      if (this.reset_email && this.reset_account) {
        var url = "user-customer"
        if (this.$route.meta.isHQ) url = "user-hq"

        api
          .unauthRequest("post", url + "?type=reset", {
            email: this.reset_email,
            account_number: this.reset_account
          })
          .then(() => {
            this.reset_success_message =
              "Please check your inbox. If the details submitted match our records you'll have received an email with further instructions."
          })
          .catch(err => {
            if (err.response.status == 404) {
              this.reset_success_message =
                "Please check your inbox. If the details submitted match our records you'll have received an email with further instructions."
            } else this.reset_error_message = "There was an error, please try again"
          })
      }
    },
    logIn() {
      this.logging_in = true
      this.login_error_message = ""
      this.$store.commit("setServiceStatus", null)

      cognito
        .logIn(this.email, this.password)
        .then(res => {
          this.logging_in = false

          if (res.result == "success")
            this.successfulLogIn().then(res => {
              if (!res.success) this.login_error_message = res.message
            })
          else if (res.result == "associateSecretCode") this.authSetup(res.secretCode, this.email)
          else if (res.result == "newPasswordRequired") this.view_state = "new-password"
          else if (res.result == "totpRequired") {
            this.view_state = "authentication-code"
            this.auth_type = res.type
          }
        })
        .catch(err => {
          this.logging_in = false

          var logError = false
          if (err.code == "NotAuthorizedException") {
            if (err.message.indexOf("expired") > -1) this.login_error_message = err.message
            else if (err.message == "User is disabled.") this.login_error_message = "Your account is locked out"
            else this.login_error_message = "Invalid username or password"
          } else if (err.code == "UserLambdaValidationException") {
            if (err.message.indexOf("Your account is locked after too many failed attempts") > -1) {
              api.unauthRequest("post", "user-customer?type=locked-out", { email: this.email })
              this.login_error_message = "Your account is locked out after too many failed attempts"
            } else {
              this.login_error_message = "There was an error, please try again"
              logError = true
            }
          } else {
            this.login_error_message = "There was an error, please try again"
            logError = true
          }

          if (logError) {
            var errorString = ""
            var errorObject = ""

            try {
              errorString = err.toString()
            } catch (stringError) {
              errorString = ""
            }
            try {
              errorObject = JSON.stringify(err)
            } catch (objectError) {
              errorObject = ""
            }

            if (err.message.indexOf("Value at 'userAlias' failed to satisfy constraint") == -1)
              api.unauthRequest("post", "log-error", {
                type: "login",
                errorString,
                errorObject,
                data: JSON.stringify({ email: this.email })
              })
          }
        })
    },
    signUp(askForAuth) {
      this.disable_signup = true
      if (!askForAuth) this.disable_signup_confirm = true

      this.signup_error_message = ""

      if (
        !this.signup_name ||
        !this.signup_company ||
        !this.signup_account_number ||
        !this.signup_telephone ||
        !this.signup_email ||
        !this.signup_password ||
        !this.signup_confirm
      ) {
        this.signup_error_message = "Please enter all fields"
        this.$modal.hide("authtype")
        this.disable_signup = false
        this.disable_signup_confirm = false
        return
      }

      if (this.signup_password !== this.signup_confirm) {
        this.signup_error_message = "Passwords do not match"
        this.$modal.hide("authtype")
        this.disable_signup = false
        this.disable_signup_confirm = false
        return
      }

      if (!this.signup_details || !this.signup_privacy) {
        this.signup_error_message = "Please agree to both terms"
        this.$modal.hide("authtype")
        this.disable_signup = false
        this.disable_signup_confirm = false
        return
      }

      if (this.signup_account_number.length != 12 || !/^\d+$/.test(this.signup_account_number)) {
        this.signup_error_message = "Please ensure your location number is 12 digits long and contains only numbers"
        this.$modal.hide("authtype")
        this.disable_signup = false
        this.disable_signup_confirm = false
        return
      }

      if (this.signup_account_number.substring(0, 2) != "02") {
        this.signup_error_message = "Please ensure your location number begins with 02"
        this.$modal.hide("authtype")
        this.disable_signup = false
        this.disable_signup_confirm = false
        return
      }

      var phone = new phoneNumber(this.signup_telephone, "GB")

      if (!phone.isValid()) {
        this.signup_error_message = "Please enter a valid telephone number"
        this.$modal.hide("authtype")
        this.disable_signup = false
        this.disable_signup_confirm = false
        return
      }

      if (!askForAuth && this.signup_auth_type == "SMS" && !phone.isMobile()) {
        this.signup_error_message =
          "You selected SMS authentication, please enter a business mobile number rather than a landline"
        this.$modal.hide("authtype")
        this.disable_signup = false
        this.disable_signup_confirm = false
        return
      }

      if (!askForAuth && this.signup_auth_type == "Email" && !this.signup_email_allowed)
        this.signup_auth_type == "Software"

      if (
        !askForAuth &&
        this.signup_auth_type == "Email" &&
        !this.signup_email_domain.split(",").includes(this.signup_email.split("@")[1])
      ) {
        this.signup_error_message =
          "You selected email authentication, please enter an email address which uses your company email domain."
        this.$modal.hide("authtype")
        this.disable_signup = false
        this.disable_signup_confirm = false
        return
      }

      if (askForAuth) {
        api
          .unauthRequest("post", "parent-account?type=email-authentication", {
            account_number: this.signup_account_number
          })
          .then(res => {
            if (res.email_allowed && res.domain) {
              this.signup_email_allowed = true
              this.signup_email_domain = res.domain
            }
          })
          .finally(() => this.$modal.show("authtype"))
      } else {
        cognito
          .signUp(
            this.signup_password,
            this.signup_name,
            this.signup_email,
            this.signup_telephone,
            this.signup_company,
            this.signup_account_number,
            this.signup_auth_type
          )
          .then(sub => {
            api
              .unauthRequest("post", "user-customer?type=signup-emails", {
                name: this.signup_name,
                email: this.signup_email,
                telephone: this.signup_telephone,
                company: this.signup_company,
                account_number: this.signup_account_number,
                sub
              })
              .catch(err => {
                var errorString = ""
                var errorObject = ""

                try {
                  errorString = err.toString()
                } catch (stringError) {
                  errorString = ""
                }
                try {
                  errorObject = JSON.stringify(err)
                } catch (objectError) {
                  errorObject = ""
                }

                api.unauthRequest("post", "log-error", {
                  type: "signup emails",
                  errorString,
                  errorObject,
                  data: JSON.stringify({
                    company: this.signup_company,
                    email: this.signup_email
                  })
                })
              })

            var p = []
            if (this.signup_auth_type != "Email") p.push(cognito.logIn(this.signup_email, this.signup_password))

            Promise.all(p)
              .then(res => {
                var email = this.signup_email
                this.signup_name = ""
                this.signup_company = ""
                this.signup_account_number = ""
                this.signup_telephone = ""
                this.signup_email = ""
                this.signup_password = ""
                this.signup_confirm = ""
                this.signup_details = false
                this.signup_privacy = false

                if (this.signup_auth_type == "Software") this.authSetup(res.secretCode, email)
                else this.view_state = "signup-success"
              })
              .catch(err => console.log(err))
              .finally(() => {
                this.$modal.hide("authtype")
                this.disable_signup = false
                this.disable_signup_confirm = false
              })
          })
          .catch(err => {
            this.$modal.hide("authtype")
            this.disable_signup = false
            this.disable_signup_confirm = false

            if (err.code == "NotAuthorizedException") {
              if (err.message.indexOf("expired") > -1) this.signup_error_message = err.message
              else if (err.message == "User is disabled.")
                this.signup_error_message =
                  "Sorry, we couldn't log you in at this time. Please contact us for more information."
              else this.signup_error_message = "Invalid username or password"
            } else if (err.code == "InvalidParameterException") {
              this.signup_error_message = err.message
            } else if (err.code == "InvalidPasswordException") {
              // this one doesn't seem to get called, above is used for invalid password
              this.signup_error_message = "Invalid password: Please choose a password with at least 10 characters, a letter, a number, and a special character"
            } else if (err.code == "UsernameExistsException") {
              this.signup_error_message = err.message
            } else if (err.code == "InvalidDomainException") {
              this.signup_error_message =
                "You selected email authentication, please enter an email address which uses your company email domain."
            } else this.signup_error_message = "There was an error, please try again"
          })
      }
    },
    setNewPassword() {
      this.temporary_error_message = ""

      if (this.new_password !== this.confirm_password) {
        this.temporary_error_message = "The passwords do not match"
        return
      }

      cognito
        .setNewPassword(this.new_password)
        .then(res => {
          if (res.result == "success") {
            this.password = this.new_password
            this.view_state = "login"
            this.logIn()
          } else if (res.result == "totpRequired") {
            this.view_state = "authentication-code"
            this.auth_type = res.type
          } else if (res.result == "associateSecretCode") this.authSetup(res.secretCode, this.email)
        })
        .catch(err => {
          if (err.code == "NotAuthorizedException") {
            this.view_state = "login"
            this.login_error_message = "Please log in again"
          } else if (err.code == "InvalidPasswordException") this.temporary_error_message = "Invalid password: Please choose a password with at least 10 characters, a letter, a number, and a special character"
          else {
            api.unauthRequest("post", "user-customer?type=reset-error", {
              err: JSON.stringify(err)
            })

            this.temporary_error_message = "There was an error, please try again"
          }
        })
    },
    verifyToken() {
      this.authsetup_error_message = ""

      if (!this.authsetup_token) {
        this.authsetup_error_message = "Please enter a token"
        return
      }

      cognito
        .verifyToken(this.authsetup_token.replace(/\s/g, ""))
        .then(() => {
          var url = "user-customer"
          if (this.$store.state.user_type == "hq-user") url = "user-hq"
          api.request("post", url + "?type=allow-software")

          this.successfulLogIn().then(res => {
            if (this.$store.state.user_type == "hq-user") api.request("post", "user-hq?type=allow-software")
            if (this.$store.state.user_type == "customer") api.request("post", "user-customer?type=allow-software")

            if (!res.success) {
              if (this.$route.meta.isHQ) this.$router.push({ name: "HQ Home" })
              else this.auth_slide = 4
            }
          })
        })
        .catch(err => {
          if (err.code == "NotAuthorizedException") {
            this.view_state = "login"
            this.login_error_message = "Please log in again"
          } else this.authsetup_error_message = "Incorrect code"
        })
    },
    authSetup(secretCode, email) {
      this.view_state = "setup-software"

      QRCode.toDataURL(
        "otpauth://totp/Loomis%20Customer%20Portal:" +
        email +
        "?secret=" +
        secretCode +
        "&issuer=Loomis%20Customer%20Portal"
      )
        .then(url => {
          this.qr_image = url
          this.qr_secret = secretCode
        })
        .catch(() => { })
    },
    MFA() {
      this.logging_in_mfa = true
      this.auth_code_error_message = ""

      if (!this.auth_code) {
        this.auth_code_error_message = "Please enter a token"
        this.logging_in_mfa = false
        return
      }

      cognito
        .MFA(this.auth_code.replace(/\s/g, ""), this.auth_type)
        .then(res => {
          if (res.result == "associateSecretCode") this.authSetup(res.secretCode, this.email)
          else
            this.successfulLogIn().then(res => {
              if (!res.success) {
                this.auth_code_error_message = res.message
              }

              this.logging_in_mfa = false
            })
        })
        .catch(err => {
          var errorString = ""
          var errorObject = ""

          try {
            errorString = err.toString()
          } catch (stringError) {
            errorString = ""
          }
          try {
            errorObject = JSON.stringify(err)
          } catch (objectError) {
            errorObject = ""
          }

          api.unauthRequest("post", "log-error", {
            type: "mfa",
            errorString,
            errorObject,
            data: JSON.stringify({ email: this.email })
          })

          if (err.code == "NotAuthorizedException") {
            if (this.auth_type == "EMAIL_MFA") {
              this.auth_code_error_message = "Incorrect code"
            } else {
              this.view_state = "login"
              this.login_error_message = "Please log in again"
            }
          } else {
            this.auth_code_error_message = "Incorrect code"
          }

          this.logging_in_mfa = false
        })
    },
    successfulLogIn() {
      this.login_error_message = ""
      this.authsetup_error_message = ""
      this.temporary_error_message = ""
      this.auth_code_error_message = ""

      return new Promise(resolve => {
        if (this.$store.state.updatedPassword) {
          this.$store.commit("clearUpdatedPassword")
          if (this.$store.state.user_type == "customer")
            api.request("post", "user-customer?type=customer-send-updated-password-email")
          if (this.$store.state.user_type == "hq-user")
            api.request("post", "user-hq?type=customer-send-updated-password-email")
        }

        cognito.getSessionDetails().then(res => {
          if (res && res["cognito:groups"] && res["cognito:groups"].length && res["cognito:groups"][0] == "approved") {
            api.request("get", "message").then(res => this.$store.commit("setMessages", res))

            if (this.$store.state.user_type == "customer") {
              api
                .request("get", "customer")
                .then(customer => {
                  this.$store.commit("setStandingOrderEndingSoon", false)
                  api
                    .request("get", "customer?type=standing-order-status&account_number=" + customer.account_number)
                    .then(standingOrderStatus => {
                      this.$store.commit("setStandingOrderEndingSoon", standingOrderStatus)
                    })

                  this.$store.commit("setCustomerDetails", customer)

                  this.$router.push("/home")
                  this.$store.commit("setSessionDetails", res)

                  resolve({ success: true })
                })
                .catch(err => {
                  if (err.response && err.response.status == 404 && err.response.data == "Customer not found") {
                    cognito.logOut()
                    resolve({ success: false, message: "This account is not active" })
                  } else {
                    cognito.logOut()
                    resolve({ success: false, message: "There has been an error, please try again" })

                    var errorString = ""
                    var errorObject = ""

                    try {
                      errorString = err.toString()
                    } catch (stringError) {
                      errorString = ""
                    }
                    try {
                      errorObject = JSON.stringify(err)
                    } catch (objectError) {
                      errorObject = ""
                    }

                    api.unauthRequest("post", "log-error", {
                      type: "'successful' site user login",
                      errorString,
                      errorObject,
                      data: JSON.stringify({ email: this.email })
                    })
                  }
                })
            }

            if (this.$store.state.user_type == "hq-user")
              api
                .request("get", "user-hq")
                .then(user => {
                  this.$store.commit("setHQUser", user)

                  this.$router.push("/hq-home")
                  this.$store.commit("setSessionDetails", res)

                  resolve({ success: true })
                })
                .catch(err => {
                  if (err.response && err.response.status == 404 && err.response.data == "User not found") {
                    cognito.logOut()
                    resolve({ success: false, message: "This account is not active" })
                  } else {
                    cognito.logOut()
                    resolve({ success: false, message: "There has been an error, please try again" })

                    var errorString = ""
                    var errorObject = ""

                    try {
                      errorString = err.toString()
                    } catch (stringError) {
                      errorString = ""
                    }
                    try {
                      errorObject = JSON.stringify(err)
                    } catch (objectError) {
                      errorObject = ""
                    }

                    api.unauthRequest("post", "log-error", {
                      type: "'successful' hq login",
                      errorString,
                      errorObject,
                      data: JSON.stringify({ email: this.email })
                    })
                  }
                })
          } else {
            cognito.logOut()
            resolve({ success: false, message: "Your account is pending approval. We'll be in touch soon!" })
          }
        })
      })
    },
    copyCode() {
      var elem = document.querySelector("#qr_secret")
      elem.setAttribute("type", "text")
      elem.select()

      try {
        var successful = document.execCommand("copy")
        var msg = successful ? "successful" : "unsuccessful"
        console.log("Testing code was copied " + msg)
        document.querySelector("#btn-copy-txt").innerHTML = "Copied!"
      } catch (err) {
        console.log("Oops, unable to copy")
      }

      /* unselect the range */
      elem.setAttribute("type", "hidden")
      window.getSelection().removeAllRanges()
    }
  },
  watch: {
    view_state: function (val) {
      this.$nextTick().then(() => {
        switch (val) {
          case "login":
            this.$refs.login_email.focus()
            break
          case "signup":
            this.$refs.signup_name.focus()
            break
          case "reset-password":
            this.$refs.reset_email.focus()
            break
          case "new-password":
            this.$refs.temporary_password.focus()
            break
          case "authentication-code":
            this.$refs.auth_code.focus()
            break
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.login {
  padding-top: 10rem;
  max-width: 92%;
  margin: auto;
}

.login.long {
  padding-top: 2rem;
}

.login-inner {
  width: 35rem;
  margin: auto;
  max-width: 100%;
}

.signup {
  padding-top: 3rem;
}

.signup .login-inner {
  width: 70rem;
  max-width: 100%;
}

.signup .row {
  width: 35rem;
  margin-left: auto;
  margin-right: auto;
}

.signup .row.full {
  width: 70rem;
  max-width: 100%;
  padding-top: 1rem;
}

.signup .chk {
  top: 0;
  margin-right: 1rem;
}

.signup .chk:focus-visible {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}

.signup label {
  cursor: pointer;
}

.login.long .login-inner {
  width: 60rem;
  text-align: center;
}

.minor {
  font-size: 1.2rem;
  opacity: 0.6;
}

.code {
  font-size: 1.2rem;
  white-space: normal;
  opacity: 0.6;
}

.central {
  text-align: center;
}

h1 {
  text-align: center;
  color: #5f6062;
  font-size: 6rem;
  font-weight: normal;
}

.step {
  border: 1px solid #f5f5f5;
  border-radius: 0.5rem;
  margin-top: 3rem;
  padding: 2rem;
  padding-bottom: 3rem;
}

.btn-copy {
  background: #f5f5f5;
  border: 0;
  padding: 0.5rem 1rem;
  color: #666;
  cursor: pointer;
}

.btn-copy svg {
  margin-right: 0.5rem;
}

p {
  text-align: center;
  margin-bottom: 2rem;
}

.note {
  opacity: 0.6;
}

.step-heading {
  color: #5f6062;
  font-size: 3rem;
  padding: 1.5rem 0;
  padding-top: 0;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 2.5rem;
}

.help {
  text-align: left;
  padding-top: 2rem;
}

.help-heading {
  color: #5f6062;
  font-size: 3rem;
  padding: 1.5rem 0;
  border-bottom: 2px solid #f1f1f1;
  margin-bottom: 1.5rem;
}

.help-heading svg {
  float: right;
  position: relative;
  bottom: -0.35rem;
}

.help a {
  color: #dc002e;
  transition: all 0.3s;
}

.help div {
  margin-top: 1rem;
}

.help a:hover {
  color: #333;
}

.help-link-right {
  float: right;
}

.help-link-spaced {
  margin-bottom: 1rem;
  display: inline-block;
}

.privacy {
  text-decoration: underline;
}

.privacy:hover {
  color: #ca0028;
}

.radios {
  text-align: center;
}

.radio {
  display: inline-block;
  margin: 1.5rem 0;
  margin-right: 3rem;
}

.authtype-modal p {
  text-align: left;
}

.authtype-modal .actions {
  text-align: center;
}

.authtype-modal .small {
  font-size: 1.3rem;
}

.authtype-modal .central {
  text-align: center;
}

.authtype-modal .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  gap: 8rem;
  margin: auto;
  max-width: 100%;
  margin-bottom: 2rem;
}

.authtype-modal .grid.triple {
  grid-template-columns: 1fr 1fr 1fr;
}

.authtype-modal .type-label {
  margin: 1rem 0;
}

.authtype-modal p a {
  text-decoration: underline;
}

.authtype-modal p a:hover {
  color: #ca0028;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 2.8rem;
  cursor: pointer;
  line-height: 2rem;
  display: inline-block;
  font-size: 2rem;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  background: #ca0028;
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

[type="radio"]:focus-visible+label:before {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}

@media screen and (max-width: 850px) {

  .authtype-modal .grid,
  .authtype-modal .grid.triple {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .authtype-modal img {
    width: 50%;
  }
}
</style>
