<template>
  <div>
    <main class="login">
      <h1>Thank You</h1>
      <div class="login-inner">
        <div class="row help">
          <div class="info" v-if="$route.query.reason == 'inactive'">
            In order to protect your account, you have been logged out automatically after a period of inactivity.
          </div>
          <div class="info" v-else>You have now logged out of the system</div>
          <div class="help-heading">Did you get what you need today?</div>
          <a href="https://loomisuk.typeform.com/to/tJFRZ2Qn" target="_blank" rel="noreferrer">&gt; Take a moment to rate
            your experience</a>
          <router-link :to="loginURL">&gt; Login</router-link>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    loginURL() {
      if (!this.$route.meta.isHQ) return { name: "index" }
      if (this.$route.meta.isHQ) return { name: "HQ Login" }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  padding-top: 10rem;
  max-width: 92%;
  margin: auto;
}

.login.long {
  padding-top: 0;
}

.login-inner {
  width: 35rem;
  margin: auto;
  max-width: 100%;
}

.central {
  text-align: center;
}

h1 {
  text-align: center;
  color: #5f6062;
  font-size: 6rem;
  font-weight: normal;
}

.info {
  text-align: center;
  display: block !important;
}

.help {
  text-align: left;
}

.help-heading {
  color: #5f6062;
  font-size: 2.2rem;
  padding: 1.5rem 0;
  border-bottom: 2px solid #f1f1f1;
  margin-bottom: 1.5rem;
}

.help a {
  color: #dc002e;
  transition: all 0.3s;
  display: block;
  margin-bottom: 0.5rem;
}

.help a:hover {
  color: #333;
}</style>
