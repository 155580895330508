<template>
  <div>
    <h1>
      {{ heading }}
      <img v-if="icon" :src="'/images/icons/' + icon" :alt="heading" class="section-icon" />
    </h1>
    <p v-if="para">{{ para }}</p>
  </div>
</template>

<script>
export default {
  props: ["heading", "icon", "para"],
  data() {
    return {}
  }
}
</script>

