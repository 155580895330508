import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import cognito from "@/services/cognito-user"
import router from "@/router"
import api from "@/services/api"
import dateUtil from "date-and-time"

Vue.use(Vuex)

const vuexSession = createPersistedState({
  storage: window.sessionStorage,
  reducer: state => ({
    hqAccountsFilter: state.hqAccountsFilter,
    myDetails: state.myDetails,
    updatedPassword: state.updatedPassword,
    customer: state.customer,
    user_type: state.user_type,
    hq_user: state.hq_user,
    service_status: state.service_status,
    hq_service_status: state.hq_service_status,
    standingOrderEndingSoon: state.standingOrderEndingSoon
    // Any more added here also need to be reset in clearSession mutation
  })
})

export default new Vuex.Store({
  plugins: [vuexSession],
  state: {
    userAttributes: null,
    authType: "",
    myDetails: [],
    messages: [],
    updatedPassword: false,
    non_delivery_dates: null,
    holiday_periods: null,
    server_offset: null,
    customer: null,
    hq_user: null,
    service_status: null,
    hq_service_status: null,
    messages: [],
    accounts: [],
    cutoffhour: 11,
    cutoffminute: 30,
    holidaycutoffhour: 15,
    holidaycutoffminute: 0,
    messageModalDismissed: false,
    user_type: "customer",
    user_management_view_state: "",
    showMenu: false,
    hqAccountsFilter: "",
    standingOrderEndingSoon: false
  },
  getters: {
    unread: state => state.messages.filter(m => !m.message_read).length,
    minOrderDate: state => {
      var minDate = new Date()
      var now = new Date()
      var today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

      var lead_time = 1
      if (state.customer && state.customer.lead_time) lead_time = state.customer.lead_time

      addWorkingDays(minDate, lead_time, [])
      if (
        now.getDay() == 6 ||
        now.getDay() == 0 ||
        now.getHours() > state.cutoffhour ||
        (now.getHours() == state.cutoffhour && now.getMinutes() >= state.cutoffminute)
      )
        addWorkingDays(minDate, 1, [])

      minDate = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate())

      if (state.holiday_periods) {
        state.holiday_periods.forEach(period => {
          var start = dateUtil.parse(period.start_date, "YYYY-MM-DD")
          var end = dateUtil.parse(period.end_date, "YYYY-MM-DD")
          var inHoliday = false

          if (start.getTime() == today.getTime()) {
            if (
              now.getHours() > state.holidaycutoffhour ||
              (now.getHours() == state.holidaycutoffhour && now.getMinutes() >= state.holidaycutoffminute)
            )
              inHoliday = true
          } else if (start.getTime() < today.getTime() && end.getTime() > today.getTime()) inHoliday = true

          if (inHoliday) {
            addWorkingDays(end, lead_time, [])

            if (end.getTime() > minDate.getTime()) minDate = end
          }
        })
      }

      return minDate
    },
    maxNonEditableDate: state => {
      var minDate = new Date()
      var now = new Date()
      var today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

      var lead_time = 1
      if (state.customer && state.customer.lead_time) lead_time = state.customer.lead_time

      addWorkingDays(minDate, lead_time, [])
      if (
        now.getDay() != 6 &&
        now.getDay() != 0 &&
        !(
          now.getHours() > state.cutoffhour ||
          (now.getHours() == state.cutoffhour && now.getMinutes() >= state.cutoffminute)
        )
      )
        subtractWorkingDays(minDate, 1, [])

      minDate = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate())

      if (state.holiday_periods) {
        state.holiday_periods.forEach(period => {
          var start = dateUtil.parse(period.start_date, "YYYY-MM-DD")
          var end = dateUtil.parse(period.end_date, "YYYY-MM-DD")
          var inHoliday = false

          if (start.getTime() == today.getTime()) {
            if (
              now.getHours() > state.holidaycutoffhour ||
              (now.getHours() == state.holidaycutoffhour && now.getMinutes() >= state.holidaycutoffminute)
            )
              inHoliday = true
          } else if (start.getTime() < today.getTime() && end.getTime() > today.getTime()) inHoliday = true

          if (inHoliday) {
            if (end.getTime() > minDate.getTime()) minDate = end
          }
        })
      }

      return minDate
    }
  },
  mutations: {
    setStandingOrderEndingSoon: (state, payload) => (state.standingOrderEndingSoon = payload),
    setUserManagementViewState: (state, payload) => (state.user_management_view_state = payload),
    setShowMenu: (state, payload) => (state.showMenu = payload),
    setHqAccountsFilter: (state, payload) => (state.hqAccountsFilter = payload),
    hideTour: state => (state.customer.first_visit = false),
    hideHQTour: state => (state.hq_user.first_visit = false),
    hideMessagesModal: state => (state.messageModalDismissed = true),
    clearSession: state => {
      state.myDetails = []
      state.updatedPassword = false
      state.customer = null
      state.user_type = "customer"
      state.hq_user = null
      state.service_status = null
      state.hq_service_status = null
      state.hqAccountsFilter = ""
      state.accounts = []
      state.standingOrderEndingSoon = false
    },
    setMessages: (state, payload) => (state.messages = payload),
    setServiceStatus: (state, payload) => (state.service_status = serviceStatuses(payload)),
    setHQServiceStatus: (state, payload) => (state.hq_service_status = serviceStatuses(payload)),
    setAccounts: (state, payload) => {
      state.accounts = payload
    },
    setHQCashProperties: state => {
      var cashCustomers = state.accounts.filter(c => c.credit_limit && c.cash_ordering)

      if (cashCustomers.length) {
        var customer = cashCustomers[0]

        Vue.set(state.hq_user, "cash_ordering", customer.cash_ordering)
        Vue.set(state.hq_user, "credit_limit", customer.credit_limit)
      }
    },
    setUserAttributes: (state, payload) => (state.userAttributes = payload),
    updatedPassword: state => (state.updatedPassword = true),
    clearUpdatedPassword: state => (state.updatedPassword = false),
    setSessionDetails: (state, payload) => {
      var details = [
        {
          type: "text",
          label: "Name",
          content: payload.name,
          property: "name",
          s: 1
        },
        {
          type: "email",
          label: "Email",
          content: payload.email,
          property: "email",
          s: 3
        },
        {
          type: "tel",
          label: "Telephone",
          content: payload.phone_number || payload["custom:phone"],
          property: payload.phone_number ? "phone_number" : "custom:phone",
          s: 4
        }
      ]

      details.forEach(d => {
        d._content = d.content
        d.editing = false
        d.error = ""
      })

      state.myDetails = []
      details.forEach(d => state.myDetails.push(d))
      state.myDetails.sort((a, b) => a.s - b.s)
      state.authType = payload["custom:auth_type"]
    },
    setNonDeliveryDates: (state, payload) => (state.non_delivery_dates = payload),
    setUserType: (state, payload) => {
      state.user_type = payload
      if (payload == "hq-user") state.customer = null
      if (payload == "customer") state.hq_user = null
    },
    setMessages: (state, payload) => (state.messages = payload),
    setHolidayPeriods: (state, payload) => (state.holiday_periods = payload),
    setServerOffset: (state, payload) => (state.server_offset = payload),
    setHQCustomer: (state, payload) => {
      state.customer = payload
      var i = state.accounts.findIndex(a => a.account_number == payload.account_number)
      state.accounts.splice(i, 1)
      state.accounts.unshift(payload)
    },
    setCustomerDetails: (state, payload) => {
      state.hq_user = null
      state.customer = payload

      state.myDetails = state.myDetails.filter(d => d.property != "custom:company")

      state.myDetails.sort((a, b) => a.s - b.s)
    },
    setHQUser: (state, payload) => {
      state.customer = null
      state.hq_user = payload
    }
  },
  actions: {
    logOut({ commit, state }) {
      var type = state.user_type
      var ext = ""
      if (type == "hq-user" || type == "hq-as-customer") ext = "-hq"

      cognito.logOut()
      commit("clearSession")
      router.push("/logged-out" + ext)
    },
    hideTour({ commit, state }) {
      commit("hideTour")
      api.request("post", "customer?type=hide-tour")
    },
    setAccounts({ commit, state }, payload) {
      commit("setAccounts", payload)
      commit("setHQCashProperties", payload)
    },
    hideHQTour({ commit, state }) {
      commit("hideHQTour")
      api.request("post", "user-hq?type=hide-tour")
    },
    hideMessagesModal({ commit, state }) {
      commit("hideMessagesModal")
    },
    logOutInactive({ commit, state }) {
      var type = state.user_type
      var ext = ""
      if (type == "hq-user" || type == "hq-as-customer") ext = "-hq"

      cognito.logOut()
      commit("clearSession")
      router.push("/logged-out" + ext + "?reason=inactive")
    },
    getNonDeliveryDates({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.non_delivery_dates)
          api.request("get", "non-delivery-date").then(dates => {
            commit("setNonDeliveryDates", dates)
            resolve()
          })
        else resolve()
      })
    },
    getHolidayPeriods({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.holiday_periods)
          api.request("get", "holiday-periods?type=customer").then(dates => {
            commit("setHolidayPeriods", dates)
            resolve()
          })
        else resolve()
      })
    },
    getServerTimezone({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.server_offset)
          api.request("get", "time-zone?type=offset").then(res => {
            commit("setServerOffset", res.offset)
            resolve()
          })
        else resolve()
      })
    }
  }
})

function addWorkingDays(d, days, holidays) {
  if (days < 0) return subtractWorkingDays(d, Math.abs(days), holidays)

  while (days > 0) {
    d.setDate(d.getDate() + 1)
    if (!isHoliday(d, holidays)) days--
  }

  return d
}

function subtractWorkingDays(d, days, holidays) {
  while (days > 0) {
    d.setDate(d.getDate() - 1)
    if (!isHoliday(d, holidays)) days--
  }

  return d
}

function isHoliday(d, holidays) {
  if (d.getDay() == 0 || d.getDay() == 6) return true

  // if date is present in the holiday list return true;
  var YYYYMMDD = dateUtil.format(d, "YYYY-MM-DD")
  return holidays.indexOf(YYYYMMDD) > -1
}

function serviceStatuses(statuses) {
  if (!statuses || !Array.isArray(statuses)) return []

  const formatDateTime = datetime => {
    if (!datetime) return null
    const date = new Date(datetime)
    let hours = date.getHours()
    let minutes = date.getMinutes()
    hours = hours < 10 ? `0${hours}` : hours
    minutes = minutes < 10 ? `0${minutes}` : minutes
    return `${hours}:${minutes}`
  }

  return statuses
    .map(status => {
      if (!status || !status.Stop_Status_name) return null

      const stopStatus = status.Stop_Status_name.toLowerCase().replace(/ /g, "")

      let result = { account_number: status.account_number, company: status.company, customer_ref: status.customer_ref }
      if (stopStatus === "awaitingroutestart" || stopStatus === "planned") {
        return { ...result, content: "Awaiting Route Start", status: "pending" }
      } else if (stopStatus === "onroute") {
        return { ...result, content: "On Route", status: "active" }
      } else if (stopStatus === "completed") {
        return {
          ...result,
          content: "Completed",
          status: "completed",
          timestamp: formatDateTime(status.Stop_Complete_UTC_datetime)
        }
      } else if (stopStatus === "customercancelled") {
        return {
          ...result,
          content: "Customer Cancelled",
          status: "cancelled-customer",
          timestamp: formatDateTime(status.Stop_Cancel_UTC_datetime),
          cancelled_reason: status.Stop_Cancel_note
        }
      } else if (stopStatus === "loomiscancelled") {
        return {
          ...result,
          content: "Loomis Cancelled",
          status: "cancelled-loomis",
          timestamp: formatDateTime(status.Stop_Cancel_UTC_datetime),
          cancelled_reason: status.Stop_Cancel_note
        }
      } else {
        return null
      }
    })
    .filter(status => status !== null)
}
