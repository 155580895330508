<template>
  <main class="content home">
    <div class="inner content-grid">
      <div class="left tourpad step2">
        <template v-if="serviceStatus">
          <page-heading heading="Today's Service Update"></page-heading>
          <status-panel :content="serviceStatus.content" :status="serviceStatus.status"
            :timestamp="serviceStatus.timestamp" :cancelled_reason="serviceStatus.cancelled_reason" />
        </template>
        <page-heading v-else heading="What can we help you with today?"></page-heading>

        <div class="info larger" v-if="$store.getters.unread && $store.state.user_type == 'customer'">
          <div class="info-left">
            <span class="info-circle"><img src="/images/icons/right-col-info-icon-white.png" alt="info" /></span>
          </div>
          <div class="info-content">
            <div>
              {{ $store.getters.unread > 1 ? "You have unread messages" : "You have an unread message" }} &nbsp; &nbsp;
              <router-link to="/messages">Click here to view</router-link>.
            </div>
          </div>
        </div>

        <template v-if="($store.state.customer.credit_limit > 0 && $store.state.customer.cash_ordering) ||
          $store.state.customer.consumables
          ">
          <h2>Service <strong>Management</strong></h2>
          <div class="blocks">
            <router-link class="block-link step4" to="/cash-order-management"
              v-if="$store.state.customer.credit_limit > 0 && $store.state.customer.cash_ordering">
              <span><span><img src="/images/icons/cash-order-management-o.png"
                    alt="Cash Order Management" /></span></span>
              <span><span>Cash Order<br />Management</span></span>
            </router-link>
            <router-link class="block-link step4" to="/consumables" v-if="$store.state.customer.consumables">
              <span><span><img src="/images/icons/consumables-o.png" alt="Order Consumables" /></span></span>
              <span><span>Order<br />Consumables</span></span>
            </router-link>
          </div>
        </template>

        <template v-if="$store.state.customer.safe_point || contactCustomerServices">
          <h2><strong>Support</strong></h2>
          <div class="blocks">
            <router-link v-if="$store.state.customer.safe_point" class="block-link" to="/request-engineer-support">
              <span><span><img src="/images/icons/request-engineer-o.png"
                    alt="Request Engineer Support" /></span></span>
              <span><span>Request<br />Engineer Support</span></span>
            </router-link>
            <router-link class="block-link" to="/contact-customer-services" v-if="contactCustomerServices">
              <span><span><img src="/images/icons/envelope-customer-services.png"
                    alt="Contact Customer Services" /></span></span>
              <span><span>Contact<br />Customer Services</span></span>
            </router-link>
          </div>
        </template>

        <h2>Account <strong>Administration</strong></h2>
        <div class="blocks">
          <router-link class="block-link" to="/report-discrepancy"
            v-if="$store.state.user_type == 'hq-as-customer' || $store.state.customer.discrepancy_all">
            <span><span><img src="/images/icons/report-shortage-o.png" alt="Report discrepancy" /></span></span>
            <span><span>Report<br />Discrepancy</span></span>
          </router-link>
          <router-link class="block-link" to="/location-barcode">
            <span><span><img src="/images/icons/barcode-o.png" alt="Download Location Barcode" /></span></span>
            <span><span>Download<br />Location Barcode</span></span>
          </router-link>

          <template v-if="$store.state.user_type == 'customer'">
            <router-link class="block-link" to="/my-details">
              <span><span><img src="/images/icons/view-edit-account-info-o.png"
                    alt="View / Edit Account Info" /></span></span>
              <span><span>View / Edit<br />Account Info</span></span>
            </router-link>
            <router-link class="block-link" to="/update-password">
              <span><span><img src="/images/icons/update-password-o.png" alt="Update password" /></span></span>
              <span><span>Update<br />Password</span></span>
            </router-link>
          </template>
        </div>

        <!-- <h2><strong>Analytics</strong></h2>

        <div class="blocks">
          <router-link class="block-link" to="/reports">
            <span><span><img src="/images/icons/reports-o.png" alt="" /></span></span>
            <span><span>Reports</span></span>
          </router-link>
        </div> -->
      </div>
      <right-column></right-column>
    </div>

    <modal :clickToClose="false" :scrollable="true" height="auto" name="welcome" width="90%" class="tour-modal">
      <a @click.prevent="declineTour()" class="modal-close" href aria-label="Close">
        <font-awesome-icon icon="times" aria-hidden="true" role="presentation" />
      </a>
      <div class="modal-heading">Welcome to the</div>
      <div class="modal-heading contrast">myLoomis Customer Portal</div>
      <p>As this is your first visit, would you like to take a quick tour of the features within our Portal?</p>
      <img src="../assets/tour.jpg" alt="Take the tour" class="modal-tour-image" />
      <div class="actions modal-actions">
        <button class="negative" @click="declineTour()"><font-awesome-icon icon="times" /> I’m OK thanks</button>
        <button @click="showIntro()"><font-awesome-icon icon="check" /> Take the Tour</button>
      </div>
    </modal>
    <modal :scrollable="true" height="auto" name="tourfinished" class="tour-modal-finish" width="90%">
      <a @click.prevent="$modal.hide('tourfinished')" class="modal-close" href>
        <font-awesome-icon icon="times" />
      </a>
      <div class="modal-heading contrast">Tour finished</div>
      <p><strong>The tour is now over. Thanks for your time and we hope you found it useful.</strong></p>
      <p>Click the ‘close’ button to dismiss.</p>
      <div class="actions modal-actions">
        <button class="negative" @click="$modal.hide('tourfinished')"><font-awesome-icon icon="times" /> Close</button>
      </div>
    </modal>
    <modal :scrollable="true" height="auto" name="messages" width="90%">
      <a @click.prevent="$modal.hide('messages')" class="modal-close" href>
        <font-awesome-icon icon="times" />
      </a>
      <div class="modal-heading contrast">
        {{ $store.getters.unread > 1 ? "You have unread messages" : "You have an unread message" }}
      </div>
      <p>Click below to read it now, or click the close button to dismiss.</p>
      <div class="actions modal-actions">
        <button @click="$router.push('/messages')"><font-awesome-icon icon="envelope" /> View message</button>
        <button class="negative" @click="dismissMessages"><font-awesome-icon icon="times" /> Close</button>
      </div>
    </modal>
    <modal :clickToClose="false" :scrollable="true" height="auto" name="standing-order" width="90%">
      <div class="modal-heading contrast">
        Order info
        <!-- <img src="/images/icons/right-col-info-icon-white.png" alt="info" /> -->
      </div>
      <p class="note">Your standing order is due for renewal shortly. To ensure uninterrupted service and a seamless
        experience, we
        kindly ask that you re-enter your required denominations at your earliest convenience.
      </p>
      <p class="go-link">
        <a href="/cash-order-management" class="button">Renew standing order</a>
      </p>
    </modal>
  </main>
</template>

<script>
import api from "@/services/api"

export default {
  data() {
    return {
      stepText: [
        "This is the Main Menu. Use this to navigate around the main sections of the Portal at any time.",
        "This area is known as the dashboard. From here, you can navigate sections of the Portal, for example, ordering cash through our ‘Service Management’ section.<br /><br />You can also access information about your account, update your password and log out through the ‘Account Administration’ section.",
        "This area gives you a countdown to our change ordering deadline for any next day deliveries.",
        "Let’s take a look at the ‘Cash Order Management’ section to see the options available to you in this area."
      ]
    }
  },
  watch: {
    standingOrderEndingSoon: function (val) {
      if (val) this.$modal.show("standing-order")
    }
  },
  computed: {
    standingOrderEndingSoon() {
      return this.$store.state.standingOrderEndingSoon
    },
    contactCustomerServices() {
      if (this.$store.state.user_type == "customer") {
        return this.$store.state.customer.site_contact_customer_services
      }

      return false
    },
    serviceStatus() {
      const status = this.$store.state.service_status
      if (status && status.length && status[0].status) return status[0]
      return null
    },
    tourSteps() {
      if (window.innerWidth < 960) {
        return [
          {
            element: document.querySelector(".step1mobile"),
            intro: this.stepText[0]
          },
          {
            element: document.querySelector(".step2"),
            intro: this.stepText[1]
          },
          {
            element: document.querySelector(".step3"),
            intro: this.stepText[2]
          },
          {
            element: document.querySelector(".step4"),
            intro: this.stepText[3]
          }
        ]
      } else {
        return [
          {
            element: document.querySelector(".step1"),
            intro: this.stepText[0]
          },
          {
            element: document.querySelector(".step2"),
            intro: this.stepText[1]
          },
          {
            element: document.querySelector(".step3"),
            intro: this.stepText[2]
          },
          {
            element: document.querySelector(".step4"),
            intro: this.stepText[3]
          }
        ]
      }
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch("logOut")
    },
    showIntro() {
      this.$modal.hide("welcome")
      this.$intro()
        .setOptions({
          steps: this.tourSteps,
          nextLabel: "Next &rarr;",
          prevLabel: "&larr; Back",
          doneLabel: "Next &rarr;",
          exitOnOverlayClick: false
        })
        .start()
        .oncomplete(() => {
          window.location.replace("/cash-order-management?showtour=true")
        })
    },
    declineTour() {
      this.$modal.hide("welcome")
      this.$store.dispatch("hideTour")
    },
    dismissMessages() {
      this.$modal.hide("messages")
      this.$store.dispatch("hideMessagesModal")
    }
  },
  created() {
    if (!this.serviceStatus)
      api.request("get", "dashboard").then(res => {
        this.$store.commit("setServiceStatus", res)
      })

    if (this.$store.state.user_type == "customer")
      api.request("get", "message").then(res => this.$store.commit("setMessages", res))

  },
  mounted() {
    if (this.$store.state.user_type == "customer") {
      var tourmodal = false
      if (this.$store.state.customer.first_visit || this.$route.query.showtour) {
        tourmodal = true
        if (this.$route.query.tour == "finished") {
          this.$modal.show("tourfinished")
          this.$store.dispatch("hideTour")
          // update customer record to say tour has been viewed
        } else {
          if (this.$store.state.customer.credit_limit > 0) this.$modal.show("welcome")
        }
      }

      if (this.$store.getters.unread > 0 && !tourmodal && !this.$store.state.messageModalDismissed) {
        this.$modal.show("messages")
        this.$store.dispatch("hideMessagesModal")
      }
    }
    if (this.standingOrderEndingSoon) this.$modal.show("standing-order")
  }
}
</script>

<style scoped>
.go-link {
  margin-top: 2rem;
}

.go-link a {
  color: #dc002e;
  transition: all 0.3s;
  text-decoration: underline;
}

.go-link a:hover {
  color: #333;
}
</style>
