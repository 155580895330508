import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import VModal from "vue-js-modal"
import VTooltip from "v-tooltip"
import flatPickr from "vue-flatpickr-component"
import "flatpickr/dist/flatpickr.css"
import IdleVue from "idle-vue"
import VueIntro from "vue-introjs"
import "intro.js/introjs.css"

import cognito from "@/services/cognito-user"

import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faBars,
  faCalendarTimes,
  faPencilAlt,
  faPlus,
  faTimes,
  faTrashAlt,
  faUserCog,
  faUsers,
  faExclamationTriangle,
  faCheck,
  faUnlockAlt,
  faPaperPlane,
  faMobileAlt,
  faCopy,
  faCircleNotch,
  faFileDownload,
  faInfoCircle,
  faUser,
  faSync,
  faExpand,
  faEnvelope,
  faChevronRight,
  faSearch,
  faUndo,
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

library.add(
  faBars,
  faTimes,
  faPencilAlt,
  faTrashAlt,
  faPlus,
  faCalendarTimes,
  faUserCog,
  faUsers,
  faExclamationTriangle,
  faCheck,
  faUnlockAlt,
  faPaperPlane,
  faMobileAlt,
  faCopy,
  faCircleNotch,
  faFileDownload,
  faInfoCircle,
  faUser,
  faSync,
  faExpand,
  faEnvelope,
  faChevronRight,
  faSearch,
  faUndo,
  faChevronDown,
  faChevronUp
)
Vue.component("font-awesome-icon", FontAwesomeIcon)

const requireComponent = require.context("./components", false, /[\w-]+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.use(VueIntro)
Vue.config.productionTip = false
Vue.use(VModal)
Vue.use(flatPickr)

const eventsHub = new Vue()
Vue.use(IdleVue, { store, eventEmitter: eventsHub, idleTime: 20 * 60 * 1000 })

VTooltip.enabled = window.innerWidth > 768
const tooltip_options = {
  defaultPlacement: "right",
  defaultTrigger: "hover",
  defaultClass: "tooltip",
  defaultHideOnTargetClick: false
}
Vue.use(VTooltip, tooltip_options)

router.beforeEach((to, from, next) => {
  store.commit("setShowMenu", false)

  if (to.meta.isPublic) next()
  else {
    cognito
      .getSessionDetails()
      .then(res => {
        if (res) {
          if (store.state.user_type == "hq-user" && to.meta.isCustomer) next({ name: "HQ Home" })
          else if (store.state.user_type == "hq-as-customer" && !to.meta.isCustomer) {
            store.commit("setUserType", "hq-user")
            next()
          } else if (to.meta.cash_ordering) {
            if (store.state.customer.cash_ordering) next()
            else next({ name: "Home" })
          } else if (to.meta.isDiscrepancy) {
            if (store.state.user_type == "hq-as-customer" || store.state.customer.discrepancy_all) next()
            else next({ name: "Home" })
          } else if (to.name == "Contact Customer Services") {
            if (store.state.user_type == "hq-user" && store.state.hq_user.hq_contact_customer_services) {
              next()
              return
            }
            if (
              store.state.user_type == "customer" &&
              (store.state.customer.safe_point || store.state.customer.site_contact_customer_services)
            ) {
              next()
              return
            }
          } else next()
        } else next({ name: "index" })
      })
      .catch(() => next({ name: "index" }))
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
