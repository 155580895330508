<template>
  <div class="info issue">
    <div class="info-left">
      <span class="info-circle"><img src="/images/icons/content-error-icon-white.png" alt="info"/></span>
    </div>
    <div class="info-content"><div v-html="content"></div></div>
  </div>
</template>

<script>
export default {
  props: ["content"],
  data() {
    return {}
  }
}
</script>
