<script>
import { Bar } from "vue-chartjs"

export default {
  extends: Bar,
  props: ["report", "chartData", "display", "type"],
  mounted() {
    this.updateChart()
  },
  watch: {
    chartData() {
      this.updateChart()
    }
  },
  methods: {
    formatCurrency(val) {
      const formatter = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 0
      })
      return formatter.format(val)
    },
    updateChart() {
      var labels = this.chartData.dates // values along x axis
      var report = this.chartData
      var type = this.type
      var formatCurrency = this.formatCurrency

      var datasets = []
      var suggestedMax = 0
      var labelString = ""
      if (this.type == "order") {
        datasets = [
          {
            label: "Coins", // legend at top
            data: this.chartData.coins,
            backgroundColor: "#CB4E0B"
          },
          {
            label: "Notes", // legend at top
            data: this.chartData.notes,
            backgroundColor: "#dc002e"
          }
        ]

        suggestedMax = 1000
        labelString = "Value"
      }
      if (this.type == "shortage") {
        datasets = [
          {
            label: "Discrepancy reports", // legend at top
            data: this.chartData.reports,
            backgroundColor: "#CB4E0B"
          }
        ]

        suggestedMax = 1
        labelString = "Claims logged"
      }

      this.renderChart(
        {
          labels,
          datasets
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                stacked: true,
                gridLines: {
                  offsetGridLines: false,
                  display: false
                },
                scaleLabel: {
                  display: true,
                  labelString: this.display,
                  fontSize: 16,
                  fontStyle: "bold",
                  fontFamily: "'Lato', 'sans-serif'"
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  min: 0,
                  suggestedMax,
                  callback: function (value) {
                    if (value % 1 === 0) {
                      return value
                    }
                  }
                },
                stacked: true,
                gridLines: { borderDash: [3, 2] },
                scaleLabel: {
                  display: true,
                  labelString,
                  fontSize: 16,
                  fontStyle: "bold",
                  fontFamily: "'Lato', 'sans-serif'"
                }
              }
            ]
          },
          tooltips: {
            backgroundColor: "#F3F3F3",
            bodyFontColor: "#5F6062",
            bodyFontStyle: "bold",
            xPadding: 10,
            yPadding: 10,
            bodyFontSize: 16,
            bodyFontFamily: "'Lato', 'sans-serif'",
            cornerRadius: 10,
            callbacks: {
              title: function (tooltipItem, data) {
                return ""
              },
              label: function (tooltipItem, data) {
                if (type == "order")
                  return [
                    "Orders: " + report.orders[tooltipItem.index],
                    "Notes: " + formatCurrency(report.notes[tooltipItem.index]),
                    "Coins: " + formatCurrency(report.coins[tooltipItem.index]),
                    "Total: " + formatCurrency(report.total[tooltipItem.index])
                  ]

                if (type == "shortage") return ["Claims logged: " + report.reports[tooltipItem.index]]
              }
            },
            displayColors: false
          }
        }
      )
    }
  }
}
</script>
