import store from "@/store"
import router from "@/router"
import api from "@/services/api"

import { AuthenticationDetails, CognitoUserPool, CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js"
import { UserPoolId, customerClientId as ClientId, HQUserPoolId, HQClientId } from "../util/vars"

import phoneNumber from "awesome-phonenumber"

var cognitoUser = null

// Switch between session and memory storage here:
let storageType = 2 // 1 = "MemoryStorage", 2 = "window.sessionStorage"

let dataMemory = {}

/** @class */
class MemoryStorage {
  /**
   * This is used to set a specific item in storage
   * @param {string} key - the key for the item
   * @param {object} value - the value
   * @returns {string} value that was set
   */
  static setItem(key, value) {
    dataMemory[key] = value
    return dataMemory[key]
  }

  /**
   * This is used to get a specific key from storage
   * @param {string} key - the key for the item
   * This is used to clear the storage
   * @returns {string} the data item
   */
  static getItem(key) {
    return Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : undefined
  }

  /**
   * This is used to remove an item from storage
   * @param {string} key - the key being set
   * @returns {string} value - value that was deleted
   */
  static removeItem(key) {
    return delete dataMemory[key]
  }

  /**
   * This is used to clear the storage
   * @returns {string} nothing
   */
  static clear() {
    dataMemory = {}
    return dataMemory
  }
}

var cognitodata = {
  UserPoolId,
  ClientId,
  Storage: storageType == 1 ? MemoryStorage : window.sessionStorage
}
var userPool = new CognitoUserPool(cognitodata)

var hqcognitodata = {
  UserPoolId: HQUserPoolId,
  ClientId: HQClientId,
  Storage: storageType == 1 ? MemoryStorage : window.sessionStorage
}
var hqUserPool = new CognitoUserPool(hqcognitodata)

export default {
  loggedIn: function () {
    return new Promise(resolve => {
      this.currentUserSession()
        .then(() => resolve(true))
        .catch(() => resolve(false))
    })
  },
  logIn: function (Username, Password) {
    return new Promise((resolve, reject) => {
      var authenticationData = {
        Username,
        Password
      }
      var authenticationDetails = new AuthenticationDetails(authenticationData)

      var userPool = new CognitoUserPool(store.state.user_type == "customer" ? cognitodata : hqcognitodata)
      var userData = {
        Username,
        Pool: userPool,
        Storage: storageType == 1 ? MemoryStorage : window.sessionStorage
      }
      cognitoUser = new CognitoUser(userData)

      var p = []
      if (store.state.user_type == "customer")
        p.push(api.unauthRequest("post", "user-customer?type=auth-type", { email: Username }))

      if (store.state.user_type != "customer")
        p.push(api.unauthRequest("post", "user-hq?type=auth-type", { email: Username }))

      Promise.all(p).then(vals => {
        if (vals[0] == "CUSTOM_AUTH") cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH")

        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: res => {
            // resolve({ result: "success" })
            resolve(mfaSetup())
          },
          onFailure: err => {
            reject(err)
          },
          customChallenge: res => {
            resolve({ result: "totpRequired", type: "EMAIL_MFA" })
          },
          newPasswordRequired: userAttributes => {
            delete userAttributes.email_verified
            store.commit("setUserAttributes", userAttributes)

            resolve({ result: "newPasswordRequired" })
          },
          mfaSetup(challengeName, challengeParameters) {
            mfaSetup(challengeName, challengeParameters)
              .then(res => resolve(res))
              .catch(err => reject(err))
          },
          totpRequired: type => {
            resolve({ result: "totpRequired", type })
          },
          mfaRequired: type => {
            resolve({ result: "totpRequired", type })
          }
        })
      })
    })
  },
  signUp: function (password, name, email, phone, company, account_number, auth_type) {
    return new Promise((resolve, reject) => {
      var userPool = new CognitoUserPool(store.state.user_type == "customer" ? cognitodata : hqcognitodata)

      var attributeList = []

      var attributeName = new CognitoUserAttribute({
        Name: "name",
        Value: name
      })
      var attributeEmail = new CognitoUserAttribute({
        Name: "email",
        Value: email
      })

      phone = new phoneNumber(phone, "GB").getNumber()
      var attributePhoneNumber = new CognitoUserAttribute({
        Name: "custom:phone",
        Value: phone
      })

      var attributeAccountNumber = new CognitoUserAttribute({
        Name: "custom:account_number",
        Value: account_number
      })

      attributeList.push(attributeName)
      attributeList.push(attributeEmail)
      attributeList.push(attributePhoneNumber)
      attributeList.push(attributeAccountNumber)

      attributeList.push(
        new CognitoUserAttribute({
          Name: "custom:auth_type",
          Value: auth_type
        })
      )

      userPool.signUp(email, password, attributeList, null, function (err, result) {
        if (err) {
          reject(err)
          return
        }

        cognitoUser = result.user

        api
          .unauthRequest("post", "user-customer?type=insert-account", {
            company,
            account_number
          })
          .catch(err => {
            var errorString = ""
            var errorObject = ""

            try {
              errorString = err.toString()
            } catch (stringError) {
              errorString = ""
            }
            try {
              errorObject = JSON.stringify(err)
            } catch (objectError) {
              errorObject = ""
            }

            api.unauthRequest("post", "log-error", {
              type: "insert account",
              errorString,
              errorObject,
              data: JSON.stringify({
                company,
                account_number
              })
            })
          })

        resolve(result.userSub)
      })
    })
  },
  setNewPassword: function (newPassword) {
    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess() {
            return resolve({ result: "success" })
          },
          onFailure(err) {
            reject(err)
          },
          totpRequired(type) {
            store.commit("updatedPassword")

            resolve({ result: "totpRequired", type })
          },
          mfaRequired(type) {
            store.commit("updatedPassword")

            resolve({ result: "totpRequired", type })
          },
          mfaSetup(challengeName, challengeParameters) {
            mfaSetup(challengeName, challengeParameters)
              .then(res => resolve(res))
              .catch(err => reject(err))
          }
        }
      )
    })
  },
  verifyToken: function (token) {
    return new Promise((resolve, reject) => {
      cognitoUser.verifySoftwareToken(token, "", {
        onSuccess: () => {
          resolve()
        },
        onFailure: err => reject(err)
      })
    })
  },
  MFA: function (token, type) {
    return new Promise((resolve, reject) => {
      if (type == "EMAIL_MFA") {
        cognitoUser.sendCustomChallengeAnswer(token, {
          onSuccess: () => {
            resolve({ result: "success" })
          },
          onFailure: err => {
            reject(err)
          }
        })
      } else
        cognitoUser.sendMFACode(
          token,
          {
            onSuccess: () => {
              resolve({ result: "success" })
            },
            onFailure: err => {
              reject(err)
            }
          },
          type
        )
    })
  },
  logOut: function () {
    if (cognitoUser != null) {
      cognitoUser = null

      this.currentUserSession()
        .then(() => {
          cognitoUser.globalSignOut({
            onSuccess: msg => console.log("onSuccess", msg),
            onFailure: err => console.log("onFailure", err)
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          cognitoUser = null
        })
    }
  },
  getSessionDetails: function () {
    return new Promise(resolve => {
      this.currentUserSession()
        .then(res => {
          if (res && res.session && res.session.idToken && res.session.idToken.payload) {
            resolve(res.user.signInUserSession.idToken.payload)
          } else resolve("")
        })
        .catch(() => resolve(""))
    })
  },
  currentUserSession: function () {
    return new Promise((resolve, reject) => {
      if (store.state.user_type == "customer") cognitoUser = userPool.getCurrentUser()
      else cognitoUser = hqUserPool.getCurrentUser()

      if (cognitoUser == null) {
        reject("User null")
        return
      }

      cognitoUser.getSession(function (err, session) {
        if (err) {
          reject(err)
          return
        }

        if (!session || !session.isValid()) {
          reject("Session invalid")
          return
        }

        resolve({ user: cognitoUser, session })
      })
    })
  },
  changePassword: function (currentPassword, newPassword) {
    return new Promise((resolve, reject) => {
      this.currentUserSession().then(() =>
        cognitoUser.changePassword(currentPassword, newPassword, function (err, result) {
          if (err) reject(err)
          else resolve(result)
        })
      )
    })
  }
}

function mfaSetup() {
  return new Promise((resolve, reject) => {
    cognitoUser.associateSoftwareToken({
      associateSecretCode: function (secretCode) {
        resolve({ result: "associateSecretCode", secretCode })
      },
      onFailure: err => {
        reject(err)
      }
    })
  })
}
