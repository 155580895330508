<template>
  <div>
    <img src="/images/completed.png" alt="" v-if="status == 'completed'" />
    <img src="/images/active.png" alt="" v-if="status == 'active'" />
    <img src="/images/pending.png" alt="" v-if="status == 'pending'" />
    <img src="/images/cancelled-customer.png" alt="" v-if="status == 'cancelled-customer'" />
    <img src="/images/cancelled-loomis.png" alt="" v-if="status == 'cancelled-loomis'" />

    <div class="info" :style="backgroundColorStyle">
      <div class="info-main">
        <div class="info-left">
          <span class="info-circle"><img src="/images/icons/right-col-info-icon-white.png" alt="info" /></span>
        </div>
        <div class="info-content">
          <div>
            <strong>Current Status:</strong>
            {{ content + (timestamp ? ` (${timestamp})` : '') + (status == 'cancelled-loomis' && !(new Date().getDate()
      == 24 && new Date().getMonth() == 11) ? ` - Recovery scheduled for tomorrow` : ``)
            }}
          </div>
        </div>
        <div class="info-more">
          <a v-if="cancelled_reason" href @click.prevent="toggleNote"
            :aria-label="`${showNote ? 'Hide' : 'Show'} extra info`">
            <font-awesome-icon v-if="!showNote" icon="chevron-down" aria-hidden="true" role="presentation" />
            <font-awesome-icon v-if="showNote" icon="chevron-up" aria-hidden="true" role="presentation" />
          </a>
        </div>

      </div>
      <div class="note" v-if="showNote">{{ cancelled_reason }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["content", "status", "timestamp", "cancelled_reason"],
  data() {
    return {
      showNote: false,
    };
  },
  methods: {
    toggleNote() {
      this.showNote = !this.showNote;
    },
  },
  computed: {
    backgroundColorStyle() {
      switch (this.status) {
        case 'completed':
          return { backgroundColor: '#2C8732' };
        case 'active':
          return { backgroundColor: '#EA5B0C' };
        case 'pending':
          return { backgroundColor: '#5F6062' };
        case 'cancelled-customer':
        case 'cancelled-loomis':
          return { backgroundColor: '#DC002E' };
      }
    },
  }
}
</script>

<style scoped>
.info {
  flex-direction: column;
}

.info-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-left,
.info-content {
  flex: 1 0 auto;
}

.info-more {
  text-align: right;
  width: 100%;
  padding: 0.5rem;
}

.note {
  width: 100%;
  padding: 0.5rem;
}
</style>