<template>
  <div>
    <main class="login">
      <div class="login-inner">

        <h1>Accessibility Statement for the myLoomis Customer Portal</h1>
        <p>Loomis UK is committed to ensuring accessibility for individuals with disabilities. We continually
          endeavour to
          improve the user experience for everyone, and always apply the relevant accessibility standards. This
          dedication
          extends to all areas, including all digital tools we provide for our customers. This accessibility statement
          applies to the myLoomis Customer Portal at <a href="https://myportal.loomis.co.uk/"
            target="_blank">https://myportal.loomis.co.uk/</a>.</p>
        <h2>Measures to support accessibility</h2>
        <p>Loomis UK takes the following measures to ensure accessibility of the myLoomis Customer Portal:</p>

        <ul>
          <li>Include accessibility as part of our mission statement.</li>
          <li>Include accessibility throughout our internal policies.</li>
          <li>Integrate accessibility into our procurement practices.</li>
          <li>Employ formal accessibility quality assurance methods.</li>
        </ul>


        <h2>Technical information about this website's accessibility</h2>
        <p>Loomis UK is committed to making its myLoomis Customer Portal accessible, in accordance with the Public
          Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.</p>

        <h2>Compliance status</h2>
        <p>This website is fully compliant with the <a href="https://www.w3.org/TR/WCAG21/" target="_blank">Web Content
            Accessibility Guidelines version 2.1</a> AA standard.</p>

        <h2>Feedback</h2>
        <p>We welcome your feedback on the accessibility of the myLoomis Customer Portal. Please let us know if you
          encounter accessibility barriers on the portal:</p>
        <ul>
          <li>E-mail: <a href="mailto:accessibility.compliance@loomis.com"
              target="_blank">accessibility.compliance@loomis.com</a></li>
          <li>Address: Loomis UK, Unit 28 Verey Road, Dunstable, LU5 4TT</li>
        </ul>
        <p>We try to respond to feedback within 2 business days.</p>


        <h2>Technical specifications</h2>
        <p>Accessibility of the myLoomis Customer Portal relies on the following technologies to work with the
          particular
          combination of web browser and any assistive technologies or plugins installed on your computer:</p>
        <ul>
          <li>HTML</li>
          <li>WAI-ARIA</li>
          <li>CSS</li>
          <li>Javascript</li>
        </ul>
        <p>These technologies are relied upon for conformance with the accessibility standards used.</p>
        <h2>Assessment approach</h2>
        <p>Loomis UK conducted an accessibility assessment of the myLoomis Customer Portal through a self-evaluation
          approach.</p>
        <h2>Formal complaints</h2>
        <p>We aim to respond to accessibility feedback within 2 business days, and to propose a solution within 10
          business
          days, should you be dissatisfied with our response to you. The Equality and Human Rights Commission (EHRC)
          is
          responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility
          Regulations 2018 (the 'accessibility regulations'). If you're not happy with how we respond to your
          complaint,
          <a href="https://www.equalityadvisoryservice.com/" target="_blank">contact the Equality Advisory and Support
            Service (EASS)</a>.
        </p>
        <h2>Formal approval of this accessibility statement</h2>
        <p>This Accessibility Statement is approved by:</p>
        <p>Loomis UK Limited</p>

        <h2>Preparation of this accessibility statement</h2>
        <p>This statement was prepared on 9 October 2023. It was last reviewed on 17 October 2023. Accessibility audits
          will
          continue to be carried out after any major revision to the website to ensure continued adherence to
          standards.
          This accessibility statement will reviewed at least once a year.</p>



        <div class="row help">
          <a href @click.prevent="$router.go(-1)">&lt; Go Back</a>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    loginURL() {
      return { name: "index" }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  padding-top: 10rem;
  max-width: 92%;
  margin: auto;
}

h1 {
  text-align: center;
  color: #5f6062;
  font-size: 6rem;
  font-weight: normal;
}

h2 {
  margin-top: 2rem;
  margin-bottom: 0;
  border-bottom: 0;
}

ul {
  margin-left: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.help {
  text-align: left;
}



a {
  color: #dc002e;
  transition: all 0.3s;
  text-decoration: underline;
}

a:hover {
  color: #333;
}
</style>
